import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ResourceService } from '../../../Library/resource/resource.service';
import { Observable, throwError } from 'rxjs';
import { ApiData } from '../../../Library/api-data/api-data.model';
import { catchError, map } from 'rxjs/operators';
import { ApiError } from '../../../Library/api-data/api-error.model';
import { environment } from '../../../../environments/environment';
import { ContentModule, ContentModuleAdapter, ModuleGroup } from '../models/content-module.model';

@Injectable({
  providedIn: 'root',
})
export class ContentModuleService extends ResourceService<ContentModule> {

  constructor(httpClient: HttpClient, adapter: ContentModuleAdapter) {
    super('/modules', httpClient, adapter);
  }

  filterByLocation(modules: ContentModule[], location: string): ContentModule[] {
    if (!modules) {
      return [];
    }
    return modules.filter((module) => {
      return module.pivot.location === location;
    });
  }

  getArticleModules(articleId: number, data?: {}): Observable<ContentModule[]> {
    const params = this.prepareParams(data);

    return this.httpClient.get<ApiData<ContentModule[]>>(`${environment.apiUrl}/articles/${articleId}/modules`, {params}).pipe(
      catchError((error) => {
        error = new ApiError(error.error);
        return throwError(error);
      }),
      map((response) => {
        response.data = this.adapter.adaptArray(response);
        return response.data;
      }),
    );
  }

  mergeModules(modules: ContentModule[]): ContentModule[] {
    if (!modules) {
      return;
    }

    // removes binding so that the original item is not changed
    modules = JSON.parse(JSON.stringify(modules));

    const mergedModules: ContentModule[] = [];
    let slotables: any = [];

    modules.slice().reverse()
      .forEach(module => {
        if (module.is_merged) {
          slotables.unshift(...module.slotables);
        } else if (slotables.length) {
          mergedModules.unshift(module);
          mergedModules[0].slotables = mergedModules[0].slotables.concat(slotables);
          slotables = [];
        } else {
          mergedModules.unshift(module);
        }
      });

    return mergedModules;
  }

  createModuleGroups(modules: ContentModule[]): ModuleGroup[] {
    if (!modules) {
      return;
    }

    const groupedModules: ModuleGroup[] = [];
    let tempModules: ContentModule[] = [];

    modules.forEach(module => {
      if (module.has_fixed_width) {
        if (tempModules.length) {
          groupedModules.push(new ModuleGroup(tempModules, 0));
          tempModules = [];
        }
        groupedModules.push(new ModuleGroup([module], module.has_fixed_width));
      } else {
        tempModules.push(module);
      }
    });

    if (tempModules.length) {
      groupedModules.push(new ModuleGroup(tempModules, 0));
      tempModules = [];
    }

    return groupedModules;
  }

}
