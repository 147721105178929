import { SeoItem } from '../../../Library/services/seo/seo-item.model';
import { environment } from '../../../../environments/environment';

export const seoRulesDefault = {
  me: new SeoItem({
    title: 'Muzički centar Crne Gore',
    description: 'Muzički centar Crne Gore objedinjuje sve segmente razvoja nacionalne muzičke kulture i organizuje komplementarne djelatnosti neophodne za njihovo funkcionisanje. Muzički centar je glavni nosilac muzičkog života na teritoriji čitave države, te koordinisanim djelovanjem pravi krupne pomake u nacionalnoj muzičkoj kulturi.',
    seo_title: 'Muzički centar Crne Gore',
    seo_fb_title: 'Muzički centar Crne Gore',
    seo_fb_description: 'Muzički centar Crne Gore objedinjuje sve segmente razvoja nacionalne muzičke kulture i organizuje komplementarne djelatnosti neophodne za njihovo funkcionisanje. Muzički centar je glavni nosilac muzičkog života na teritoriji čitave države, te koordinisanim djelovanjem pravi krupne pomake u nacionalnoj muzičkoj kulturi. fb',
    seo_fb_image_url: '/assets/img/seo/mc_seo_fb.jpg',
    seo_fb_type: 'website',
    seo_tw_title: 'Muzički centar Crne Gore',
    seo_tw_description: 'Muzički centar Crne Gore objedinjuje sve segmente razvoja nacionalne muzičke kulture i organizuje komplementarne djelatnosti neophodne za njihovo funkcionisanje. Muzički centar je glavni nosilac muzičkog života na teritoriji čitave države, te koordinisanim djelovanjem pravi krupne pomake u nacionalnoj muzičkoj kulturi. tw',
    seo_tw_image_url: '/assets/img/seo/mc_seo_tw.jpg',
    seo_keywords: 'Muzički, Centar, Crna Gora',
    url: environment.websiteUrl,
  }),
  en: new SeoItem({
    title: 'Montenegrin Music Center',
    description: 'Montenegrin Music Center description',
    seo_title: 'Montenegrin Music Center',
    seo_fb_title: 'Montenegrin Music Center',
    seo_fb_description: 'Montenegrin Music Center description',
    seo_fb_image_url: '/assets/img/seo/mc_seo_fb.jpg',
    seo_description: 'Montenegrin Music Center description',
    seo_tw_title: 'Montenegrin Music Center',
    seo_tw_description: 'Montenegrin Music Center description',
    seo_tw_image_url: '/assets/img/seo/mc_seo_tw.jpg',
    seo_fb_type: 'website',
    seo_keywords: 'Music, Center, Montenegro',
    url: environment.websiteUrl,
  })
};

