import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SeoItem } from './seo-item.model';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoRobotsService {

  constructor(
    private metaTagService: Meta
  ) {
  }

  reset(): void {
    this.metaTagService.removeTag('name="robots"');
  }

  refresh(seoItem: SeoItem): void {

    let index = seoItem.index ? 'index' : 'noindex';
    let follow = seoItem.follow ? 'follow' : 'nofollow';

    if (!environment.production) {
      index = 'noindex';
      follow = 'nofollow';
    }

    this.metaTagService.updateTag({
      name: 'robots',
      content: index + ',' + follow
    });
  }
}
