export const environment: any = {
  production: true,
  staging: false,
  apiUrl: 'https://api.muzickicentar.com/v1',
  baseApiUrl: 'https://api.muzickicentar.com',
  websiteUrl: 'https://www.muzickicentar.com',
  mediaUrl: 'https://storage.medialib.dev/media/',
  donationsMerchantUrl: '',
  mailChimpEndpoint : ''
};
