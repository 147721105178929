import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a60',
  templateUrl: './slot-a60.component.html'
})
export class SlotA60Component implements OnInit {

  @Input() slotable: Slot;
  @Input() index: number;
  imageLoaded: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onImageLoad(evt): void {
    if (evt && evt.target) {
      this.imageLoaded = true;
    }
  }

}
