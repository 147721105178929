import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { ArtistRoleTranslation, ArtistRoleTranslationAdapter } from './artist-role-translation.model';
import { Artist, ArtistAdapter } from './artist.model';

export class ArtistRole {
  public id?: number;
  is_adapted: boolean = false;
  translation?: ArtistRoleTranslation;
  translations?: ArtistRoleTranslation [];
  artists: Artist[];

  constructor(
    data?: Partial<ArtistRole>
  ) {
    Object.assign(this, data);
  }
}


@Injectable({
  providedIn: 'root'
})
export class ArtistRoleAdapter extends BaseAdapter<ArtistRole> {

  constructor(
    private artistAdapter: ArtistAdapter,
    private artistRoleTranslationAdapter: ArtistRoleTranslationAdapter) {
    super(ArtistRole);
  }

  adapt(data: any): ArtistRole {
    const item = super.adapt(data);
    if (item.is_adapted) {
      return item;
    }

    if (item.translation) {
      item.translation = this.artistRoleTranslationAdapter.adapt({data: item.translation});
    }

    if (item.artists) {
      item.artists = this.artistAdapter.adaptArray(item.artists);
    }

    if (item.translations) {
      item.translations = this.artistRoleTranslationAdapter.adaptArray({data: item.translations});
    }

    item.is_adapted = true;
    return item;
  }
}
