<div class="container my-12 md:my-15">

  <header *ngIf="!contentModule.name_hidden" class="md:mx-2/24 mb-7 sm:mb-4">
    <h1 class="text-28 md:text-36 text-neutral-900 font-everett font-750 leading-122">
      {{ contentModule.name }}
    </h1>
  </header>

 <div *ngIf="apiLoaded | async" class="md:mx-2/24 mt-8 sm:mt-12 h-56 sm:h-90">
  <google-map
    width="100%"
    height="100%"
    [center]="center"
    [zoom]="options.zoom"
    [options]="options"
  >
    <map-marker
      #shopmarker="mapMarker"
      [position]="marker.position"
      [title]="marker.name"
      [options]="marker.options"
    >
    </map-marker>
  </google-map>

</div>

</div>
