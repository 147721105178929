<div class="container my-12 sm:my-15">

  <header *ngIf="!contentModule.name_hidden">
    <div class="my-8 flex items-center">
      <span class="text-22 font-700 text-neutral-900 leading-125 pr-2">{{ contentModule.name }}</span>
      <div class="flex-1 border-b-2 border-neutral-100"></div>
    </div>
  </header>

  <div class="flex flex-row flex-wrap justify-start">
    <div *ngFor="let slotable of contentModule.slotables; let index = index;"
         class="w-12/24 md:w-4/24 mb-15 md:mr-1/24"
         [ngClass]="{ 'pr-2 md:pr-0' : index % 2 === 0,
                      'pl-2 md:pl-0': index % 2 !== 0,
                       'md:ml-2/24' : index % 4 ===0 }">
      <app-slot-a130 [slotable]="slotable" [index]="index"></app-slot-a130>
    </div>
  </div>

</div>
