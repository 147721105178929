<div class="flex flex-col md:flex-row">
  <div class="w-full md:w-13/24 relative aspect-video md:ml-2/24">
    <img class="z-20 relative transition-opacity duration-200 ease-out delay-100"
         style="filter: blur(20px); transform: scale(1.1)" src="{{ slotable.microthumb }}" alt="microthumb"
         [ngClass]="imageLoaded ? 'opacity-0' : 'opacity-1'"
    >
    <picture class="absolute inset-0 z-30">
      <source srcset="{{ slotable.image.i_814x457 }}" media="(min-width: 768px)">
      <source srcset="{{ slotable.image.i_814x457 }}" media="(min-width: 375px)">
      <source srcset="{{ slotable.image.i_496x278 }}" media="(max-width: 375px)">
      <img class="absolute inset-0 object-center object-cover w-full h-full"
           srcset="{{ slotable.image.i_496x278 }}" alt="{{ slotable.title }}" (load)="onImageLoad($event)">
    </picture>
  </div>
  <div class="w-full md:w-7/24 pt-7 sm:pt-12 pb-15 sm:pb-12 px-10 flex flex-col"
       [ngStyle]="{'background-color': color}">
    <span class="text-20 text-neutral-white font-everett font-750 leading-122">
      {{ slotable.title }}
    </span>
    <div class="flex-1 mt-6 sm:mt-8 mb-6 overflow-hidden">
      <span class="text-neutral-white text-14 leading-155">
        {{ slotable.description }}
      </span>
    </div>
    <a href="{{ slotable.url }}"
       class="btn btn--small btn__secondary--on-dark mr-auto">
      <span i18n="@@more_details">Detaljnije</span>
    </a>
  </div>
</div>
