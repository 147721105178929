import {Component, Input, OnInit} from '@angular/core';
import {ContentModuleBaseComponent} from '../content-module-base.component';
import {ContentModule} from '../../models/content-module.model';
import { currentLocale, routes as routesTrans } from '../../../../Library/routes';

@Component({
  templateUrl: './content-module-a090.component.html'
})
export class ContentModuleA090Component implements OnInit, ContentModuleBaseComponent {

  @Input() contentModule: ContentModule;
  articlesRoute: string = `/${routesTrans[currentLocale].articles}`;

  ngOnInit(): void {
  }

}
