import { Injectable, Injector } from '@angular/core';
import { Media, MediaAdapter } from '../../../Library/media/models/media.model';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { Slot, SlotAdapter } from './slot.model';

export class ContentModule extends Resource<Request> {
  id: number;
  name: string;
  layout: string;
  slotables?: Slot[];
  image: Media;
  layout_type: string;
  slot_type: string;
  slot_count: number;
  has_fixed_width: number;
  has_rss: boolean;
  data_source?: string;
  data_source_key?: string;
  data_source_object_ids?: string[] | string;
  order_by?: string;
  interval?: number;
  article_type_id?: number;
  created_at: string;
  updated_at: string;
  name_hidden: boolean;
  desktop_hidden: boolean;
  mobile_hidden: boolean;
  is_highlighted: boolean;
  is_merged: boolean;
  color: string;
  column_width: string;
  article_organizational_unit_id: number;
  pivot: any;
  is_adapted: boolean = false;
  video_url: string;

  public constructor(data?: {}) {
    super(data);

    this.init();
  }

  init(): void {
    if (!this.slotables) {
      this.slotables = [];
    }

    if (this.data_source === 'article-type') {
      this.data_source_key = 'at';
    } else {
      this.data_source_key = 'tags';
    }

    if (!this.pivot) {
      this.pivot = {};
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ContentModuleAdapter extends BaseAdapter<ContentModule> {

  constructor(
    private injector: Injector,
    private mediaAdapter: MediaAdapter
  ) {
    super(ContentModule);
  }

  adapt(data: any): ContentModule {
    const item = super.adapt(data);

    if (item.is_adapted) {
      return item;
    }

    if (item.slotables) {
      const slotAdapter = this.injector.get<SlotAdapter>(SlotAdapter);
      item.slotables = slotAdapter.adaptArray({data: item.slotables}, item.slot_type);
    }

    if (item.image) {
      item.image = this.mediaAdapter.adapt(item.image);
    }

    if (!Array.isArray(item.data_source_object_ids)) {
      item.data_source_object_ids = item.data_source_object_ids.split('|');
    }

    if (item.data_source_object_ids) {
      item.data_source_object_ids = item.data_source_object_ids.filter(e => e.length > 0);
    }

    item.is_adapted = true;

    return item;
  }
}

export class ModuleGroup {
  modules: ContentModule[];
  hasFixedWidth: number;
  isHighlighted: number = 0;

  constructor(contentModules: ContentModule[], hasFixedWidth: number) {
    this.modules = contentModules;
    this.hasFixedWidth = hasFixedWidth;
    this.isHighlighted = this.isModuleHighlighted(contentModules) ? 1 : 0;
  }

  isModuleHighlighted(modules: ContentModule[]): ContentModule {
    return modules.find(module => module.is_highlighted);
  }

}
