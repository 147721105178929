import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-slot-a30',
  templateUrl: './slot-a30.component.html'
})
export class SlotA30Component implements OnInit {
  @Input() slotable: any;
  @Input() videoId: string;

  safeVimeoUrl: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    const vimeoUrl: string = 'https://player.vimeo.com/video/' + this.videoId;

    this.safeVimeoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(vimeoUrl);
  }


}
