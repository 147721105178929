<div class="container my-12 sm:my-15">

  <header *ngIf="!contentModule.name_hidden" class="w-full mb-7 sm:mb-10 flex justify-between items-center">
    <h1 class="text-28 md:text-36 text-neutral-900 font-everett font-750 leading-122">
      {{ contentModule.name }}
    </h1>
    <a *ngIf="contentModule.slot_type !== 'link'"
       class="hidden sm:block btn__link"
       [routerLink]="[ articlesRoute ]">
      <span i18n="@@show_all">Pogledaj sve</span>
      <fa-icon [icon]="['fal', 'angle-right']" class="ml-2 w-4 h-4"></fa-icon>
    </a>
  </header>

  <div class="flex flex-row flex-wrap justify-start">
    <div *ngFor="let slotable of contentModule.slotables; let index = index;"
         class="w-full sm:w-12/24 md:w-8/24 mb-16"
         [ngClass]="{ 'sm:pr-2 sm:pl-0' : index % 2 === 0,
                      'sm:pl-2 sm:pr-0' : index % 2 !== 0,
                      'md:pr-[10px] md:pl-0' : index % 3 === 0,
                      'md:px-[5px]' : index % 3 === 1,
                      'md:pl-[10px] md:pr-0' : index % 3 === 2
                    }">
      <app-slot-a90 [slotable]="slotable" [slotType]="contentModule.slot_type"></app-slot-a90>
    </div>
  </div>

  <div class="sm:hidden mt-7">
    <a *ngIf="contentModule.slot_type !== 'link'"
       class="btn__link"
       [routerLink]="[ articlesRoute ]">
      <span i18n="@@show_all">Pogledaj sve</span>
      <fa-icon [icon]="['fal', 'angle-right']" class="ml-2 w-4 h-4"></fa-icon>
    </a>
  </div>

</div>
