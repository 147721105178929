import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../../../Library/resource/resource.service';
import { Page, PagesAdapter } from '../models/page.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiError } from '../../../Library/api-data/api-error.model';

@Injectable()

export class PagesService extends ResourceService<Page> {

  constructor(httpClient: HttpClient, adapter: PagesAdapter) {
    super('/pages', httpClient, adapter);
  }

  getByPath(path: string, data: any = {}, token?: string): Observable<Page> {
    let url = `${this.url}/path`;
    const headers = {
      'fl-api-response-type': 'module',
    };

    if (token) {
      url += '/preview';
      data.preview_mode = 'true';
      headers['fl-access-token'] = token;
    }

    data.path = path;

    const params = this.prepareParams(data);

    return this.httpClient.get<Page>(url, {params, headers}).pipe(
      catchError((response) => {
        const error = new ApiError(response.error);

        return throwError(error);
      }),
      map((response: any) => {
        if ((response as any).message === 'Redirect') {
          response.data.redirect = true;
          response.data.redirectUrl = response.redirectUrl;
          response.data.statusCode = response.statusCode;
        }

        return this.adapter.adapt(response);
      }),
    );
  }
}
