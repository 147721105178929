<div class="flex flex-col">
  <div class="flex flex-wrap align-center text-neutral-900">
    <div class="order-1 w-auto my-auto sm:my-0 sm:w-5/24 md:ml-2/24 flex flex-col pr-4">
      <span class="text-16 sm:text-18 font-700 leading-150 sm:leading-100 capitalize">
        {{ slotable.startDate | date: 'EEEE' }}
      </span>
      <span class="text-42 sm:text-42 font-700 leading-115">
        {{ slotable.startDate | date: 'dd.' }}
      </span>
      <span class="text-16 leading-150 capitalize">
        {{ slotable.startDate | date: 'MMMM yyyy' }}
      </span>
      <div class="inline-block">
        <span class="text-16 leading-150 font-700" i18n="@@at">u</span>
        <span class="text-16 leading-150 font-700">&nbsp;{{ slotable.start_time }}</span>
      </div>
      <span class="text-16 leading-150 font-700">{{slotable.venueTitle}}</span>
    </div>

    <div [ngStyle]="{ 'border-color': slotable.color }"
         class="order-3 sm:order-2 container flex-none sm:flex-1 flex flex-col border-l-12 border-neutral-400 pl-4
         sm:pr-8 sm:min-h-56 font-700 text-14 leading-140 text-neutral-900 mt-9 sm:mt-0">
      <span [ngStyle]="{ 'color' : slotable.color }">{{ slotable.programTitle }}</span>
      <a [routerLink]="[ slotable.url ]" [queryParams]="{event: slotable.id}"
         class="mt-1 font-archivo font-700 text-20 md:text-22 leading-125">{{ slotable.performanceTitle }}</a>
      <div *ngFor="let artist_role of slotable.artist_roles"
           class="space-y-4 mt-4">
        <div *ngFor="let artist of artist_role.artists"
             class="flex flex-col">
          <span class="text-neutral-400">{{ artist_role.translation?.artist_role?.translation?.title }}</span>
          <span> {{ artist.full_name }}</span>
        </div>
      </div>
      <div class="mt-auto pt-4">
        <a [routerLink]="[ slotable.url ]" [queryParams]="{event: slotable.id}"
           class="mr-auto btn btn--small btn__secondary w-35 h-10 bg-transparent " i18n="@@more_details">
          Detaljnije
        </a>
      </div>
    </div>

    <div class="order-2 sm:order-3 relative w-48 h-48 sm:w-56 sm:h-56 xs:ml-auto sm:ml-0 md:mr-3/24">
      <img class="z-20 relative transition-opacity duration-200 ease-out delay-100"
           style="filter: blur(20px); transform: scale(1.1)" src="{{ slotable.microthumb }}" alt="microthumb"
           [ngClass]="imageLoaded ? 'opacity-0' : 'opacity-1'"
      >
      <picture class="absolute inset-0 z-30">
        <source srcset="{{ slotable.image.i_454x454 }}">
        <img class="absolute inset-0 object-center object-cover w-full h-full"
             srcset="{{ slotable.image.i_454x454 }}" alt="{{ slotable.title }}" (load)="onImageLoad($event)">
      </picture>
    </div>
  </div>
  <div class="md:mx-2/24 mt-9 mb-6">
    <div class="w-full border-b border-b-neutral-200"></div>
  </div>
</div>
