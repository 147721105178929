<div id="page-detail" *ngIf="item" [style]="style">
  <div *ngFor="let group of groupedModules, let first = first" class="w-full"
       [ngClass]="{ 'bg-gray-300' : group.isHighlighted, 'pt-16' : first, 'pt-0': !first }">
    <div [ngClass]="{ 'container flex flex-col sm:flex-row': !group.hasFixedWidth }">
      <div *ngFor="let module of group.modules" class="block">
        <app-content-module class="w-full" [contentModule]="module"></app-content-module>
      </div>
    </div>
  </div>
</div>
