import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { routes, currentLocale } from 'src/app/Library/routes';

export class MenuItem extends Resource<Request> {

  public position: number;
  public slug: string;
  public state: string;
  public title: string;
  public type: string;
  public url: string;
  public query_param: any;
  public link: string;
  public level: number;
  public menuable: any;
  public menuable_type: string;
  public menuable_id: number;

  public items: MenuItem[];

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class MenuItemAdapter extends BaseAdapter<MenuItem> {

  constructor() {
    super(MenuItem);
  }

  adapt(data: any): MenuItem {
    const item = super.adapt(data);

    if (item.type === 'custom-url') {
      item.link = item.url;
    }

    if (item.url.includes('?')) {
      const splitedUrl = item.url.split('?');
      item.link = splitedUrl[0];
      const queryParams = splitedUrl[1].split('&');
      item.query_param = {};
      for (const param of queryParams) {
        const splitedQueryParam = param.split('=');
        item.query_param[splitedQueryParam[0]] = splitedQueryParam[1];
      }
    }

    if (item.type === 'placeholder') {
      item.link = '#';
    }

    if (item.type === 'page' && item.menuable) {
      item.link = this.getLinkLocale() + '/' + item.menuable.path;
    }

    if (item.type === 'general-link') {
      item.link = this.getLinkLocale() + '/' + routes[currentLocale][item.state];
    }

    if (item.items) {
      item.items = this.adaptArray(item.items);
    }

    return item;
  }

  private getLinkLocale(): string {
    return currentLocale === 'me' ? '' : '/' + currentLocale;
  }
}
