import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ds-input-fields',
  templateUrl: './ds-input-fields.component.html'
})
export class DsInputFieldsComponent implements OnInit {

  inputField: string;
  searchField: string;
  searchFieldOnDark: string;
  checkbox: string;
  inputFieldOnDark: string;

  constructor() {
  }

  ngOnInit(): void {
    this.inputField =
      `<div class="ds-input-wrapper">
        <label for="ds-input-field" class="ds-input-label">Title</label>
        <input id="ds-input-field"
               type="text"
               placeholder="Input text"
               class="ds-input-field">
        <span class="ds-input-error-message">Error message</span>
      </div>`;
    this.searchField =
      `<div class="relative">
        <input id="ds-search-field"
               type="text"
               placeholder="Search"
               class="ds-search-field">
        <div class="ds-search-utilities">
          <button class="ds-search-utility pr-4 border-r border-neutral-400">
            <fa-icon [icon]="['fal','xmark']"></fa-icon>
          </button>
          <button type="submit" class="ds-search-utility px-4">
            <fa-icon [icon]="['fal','magnifying-glass']"></fa-icon>
          </button>
        </div>
      </div>`;
    this.checkbox =
      `<input id="ds-input-checkbox"
             type="checkbox"
             class="ds-input-checkbox">`;
    this.inputFieldOnDark =
      `<div class="ds-input-wrapper w-full">
            <label for="email" class="ds-input-label--on--dark">Label</label>
            <input id="email"
                   type="text"
                   autocomplete="off"
                   i18n-placeholder=”@@label”
                   placeholder="placeholder"
                   class="ds-input-field--on--dark">
          </div>`;

    this.searchFieldOnDark =
      `<div class="relative w-full h-12">
          <input id="ds-search-field--on-dark"
                 type="text"
                 i18n-placeholder=”@@label”
                 placeholder="Placeholder"
                 autocomplete="off"
                 class="ds-search-field--on-dark">
          <div class="ds-search-utilities">
            <button class="ds-search-utility--on-dark pr-4">
              <fa-icon [icon]="['fal','xmark']" class="w-5 h-5"></fa-icon>
            </button>
            <span class="w-[1px] bg-neutral-400 h-6"></span>
            <button class="ds-search-utility--on-dark text-neutral-white px-4">
              <fa-icon [icon]="['fal','magnifying-glass']"
                       class="w-5 h-5"></fa-icon>
            </button>
          </div>
        </div>`;
  }

}
