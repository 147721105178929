<div
  class="fixed top-0 w-full bg-neutral-900 z-120 font-700 h-16">
  <div class="container relative h-full flex justify-between items-center text-neutral-white">
    <div class="w-12/24 lg:w-8/24 order-2 lg:order-1 flex justify-end lg:justify-start items-center">

      <!--      HAMBURGER-->

      <button class="menu-button" [disabled]="disableButton" id="menu" (click)="toggleMenuDialog()">
        <div class="strip strip1"></div>
        <div class="strip strip2" id="strip2"></div>
        <div class="strip strip3"></div>
      </button>

    </div>
    <div class="w-12/24 lg:w-8/24 order-1 lg:order-2 flex justify-start lg:justify-center items-center">
      <a [routerLink]="['/']"
         class="hidden lg:block">
        <img src="assets/img/logos/logo-mc.png"
             width="324"
             height="89"
             i18n-alt="@@header_mc"
             alt="Muzički centar"
             class="w-[162px] h-[45px]">
      </a>
      <a [routerLink]="['/']"
         class="block lg:hidden">
        <img src="assets/img/logos/logo-mc-mobile.png"
             width="128"
             height="62"
             i18n-alt="@@header_mc"
             alt="Muzički centar"
             class="w-[64px] h-[30px]">
      </a>
    </div>
    <div class="w-8/24 flex justify-end hidden lg:flex order-3">
      <button  (click)="goToRepertoaire()" class="flex items-center border-r border-neutral-white">
        <span
          class="text-14 leading-133 hover:underline mt-1 uppercase font-archivo font-700"
          i18n="@@header_repertoire">Repertoar</span>
        <fa-icon [icon]="['fal', 'calendar']"
                 class="ml-3 w-6 h-6 mr-4 text-neutral-200 hover:text-neutral-white"></fa-icon>
      </button>
      <span class="flex items-center text-14 leading-133">
        <button (click)="goToProfile()">
          <fa-icon [icon]="['fal', 'circle-user']"
                   class="ml-4 w-6 h-6 text-18 mr-4 flex justify-center items-center text-neutral-200
                   hover:text-neutral-white"></fa-icon>
        </button>
      </span>
    </div>
  </div>
  <div
    class="w-full">
        <app-header-mobile
          [headerMenu]="headerMenu"
          [routeTranslations]="routeTranslations"
          [menuVisible]="menuVisible"
          [menuTransition]="menuTransition"
          [locale]="locale"
          (closeMenu)="toggleMenuDialog()"
          class="block lg:hidden"></app-header-mobile>
    <app-header-desktop
      [headerMenu]="headerMenu"
      [menuVisible]="menuVisible"
      [menuTransition]="menuTransition"
      (closeMenu)="toggleMenuDialog()"
      class="hidden lg:block"></app-header-desktop>
  </div>
</div>
