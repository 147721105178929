import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a90',
  templateUrl: './slot-a90.component.html',
  styleUrls: ['./slot-a90.component.scss']
})
export class SlotA90Component implements OnInit {
  @Input() slotable: Slot;
  @Input() type: string;
  @Input() name: string;
  @Input() slotType: string;
  imageLoaded: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onImageLoad(evt): void {
    if (evt && evt.target) {
      this.imageLoaded = true;
    }
  }

}
