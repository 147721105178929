<a class="block relative w-full aspect-video hover:cursor-pointer a90-wrapper"
   href="{{ slotable.url }}">
  <div class="w-full aspect-video">
    <img class="z-20 relative  transition-opacity duration-200 ease-out delay-100"
         style="filter: blur(20px);" src="{{ slotable.microthumb }}" alt="microthumb"
         [ngClass]="imageLoaded ? 'opacity-0' : 'opacity-1'"
    >
    <picture class="absolute inset-0 z-30">
      <source srcset="{{ slotable.image.i_496x278 }}" media="(min-width: 375px)">
      <source srcset="{{ slotable.image.i_496x278 }}" media="(max-width: 375px)">
      <img class="absolute inset-0 object-center object-cover w-full h-full"
           srcset="{{ slotable.image.i_496x278 }}" alt="{{ slotable.title }}" (load)="onImageLoad($event)">
    </picture>
  </div>

  <div class="absolute z-40 top-40 right-0 w-20/24 bg-neutral-white py-3 pl-3 pr-1">
    <h6 class="text-16 text-neutral-900 font-archivo font-750 leading-150">
      {{ slotable.title }}
    </h6>
    <span *ngIf="slotable.publishedAtDate"
          class="text-16 text-neutral-400 font-700">
        {{ slotable.publishedAtDate | date : 'dd.MM.yyyy' }}
      </span>
  </div>
</a>
