import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocaleService } from '../services/locale.service';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  constructor(
    private localeService: LocaleService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isApiUrl) {
      request = request.clone({
        setHeaders: {
          'fl-website': 'true',
          locale: this.localeService.getLocale(),
        },
      });
    }

    return next.handle(request);
  }
}
