<a class="flex flex-col items-center hover:underline text-center" [routerLink]="[ artistRoute ]">
  <div class="relative w-full aspect-square rounded-full">
    <img class="z-20 relative transition-opacity duration-200 ease-out delay-100 rounded-full"
         style="filter: blur(20px); transform: scale(1.1)" src="{{ slotable.microthumb }}" alt="microthumb"
         [ngClass]="imageLoaded ? 'opacity-0' : 'opacity-1'"
    >
    <img class="absolute inset-0 object-center object-cover w-full h-full z-30 rounded-full"
           src="{{ slotable.image.i_454x454 }}"
         alt="{{ slotable.name }}"
         appImgFallback="/assets/img/default/mc-default-me-i_454x454.jpg"
         (load)="onImageLoad($event)">
  </div>
  <span class="mt-5 sm:mt-4 text-16 text-neutral-400 font-700 leading-150 uppercase">{{ slotable.role }}</span>
  <span class="mt-1 text-18 text-neutral-900 font-archivo font-800">{{ slotable.title }}</span>
</a>
