import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { ArtistTranslation, ArtistTranslationAdapter } from './artist-translation.model';
import { Media, MediaAdapter } from '../../../Library/media/models/media.model';
import { ProgramAdapter, Program } from '../../program/models/program.model';
import { HasSeoItem } from '../../../Library/services/seo/has-seo-item';
import { SeoItem } from '../../../Library/services/seo/seo-item.model';
import Helpers from '../../../Library/helpers';
import { seoRulesDefault } from '../../routing/seo-rules/seo-rules-default';
import { currentLocale } from '../../../Library/routes';
import { Gallery, GalleryAdapter } from '../../../Library/gallery/models/gallery.model';

export class Artist implements HasSeoItem {
  public id?: number;
  is_adapted: boolean = false;
  public full_name?: string;
  has_biography: boolean;
  image: Media;
  gallery: Gallery;
  public seo_image?: Media;
  public microthumb: string;
  locale: string;
  translation: ArtistTranslation;
  slug: string;
  programs?: Program [];


  constructor(
    data?: Partial<Artist>
  ) {
    Object.assign(this, data);
  }

  getSeoItem(): SeoItem {

    const title = this.translation?.seo_title ? this.translation?.seo_title : this.full_name;

    let description = this.translation?.seo_description;
    if (!description) {
      description = seoRulesDefault[currentLocale].description;
    }


    const seoItem = new SeoItem({
      title,
      description,
      seo_fb_title: this.translation?.seo_fb_title ? this.translation?.seo_fb_title : title,
      seo_fb_description: this.translation?.seo_fb_description ? this.translation?.seo_fb_description : description,
      seo_fb_type: 'website',
      seo_tw_title: this.translation?.seo_tw_title ? this.translation?.seo_tw_title : title,
      seo_tw_description: this.translation?.seo_tw_description ? this.translation?.seo_tw_description : description,
      seo_tw_type: 'website',
    });

    return Helpers.attachSeoImages(this.translation, seoItem);

  }
}


@Injectable({
  providedIn: 'root'
})
export class ArtistAdapter extends BaseAdapter<Artist> {

  constructor(
    private programAdapter: ProgramAdapter,
    private artistTranslationAdapter: ArtistTranslationAdapter,
    private mediaAdapter: MediaAdapter,
    private galleryAdapter: GalleryAdapter,
    @Inject(LOCALE_ID) private localeCode: string
  ) {
    super(Artist);
  }

  adapt(data: any): Artist {
    let item;
    if (data.artist) {
      item = super.adapt(data.artist);
    } else {
      item = super.adapt(data);
    }

    if (item.is_adapted) {
      return item;
    }

    if (item.translation) {
      item.translation = this.artistTranslationAdapter.adapt({data: item.translation});
    }

    if (item.programs) {
      item.programs = this.programAdapter.adaptArray(item.programs);
    }

    item.has_biography = !!(item.translation && item.translation.biography);

    switch (this.localeCode) {
      case 'en':
        item.locale = 'en';
        break;
      case 'sr-Latn-ME':
        item.locale = 'me';
        break;
    }

    if (item.image) {
      item.image = this.mediaAdapter.adapt(item.image);
    } else if (item.seo_image) {
      item.image = item.seo_image;
    } else {
      item.image = new Media({
        i_378x252: `/assets/img/default/mc-default-${item.locale}-i_378x252.jpg`,
        i_244x136: `/assets/img/default/mc-default-${item.locale}-i_244x136.jpg`,
        i_496x278: `/assets/img/default/mc-default-${item.locale}-i_496x278.jpg`,
        i_814x457: `/assets/img/default/mc-default-${item.locale}-i_814x457.jpg`,
        i_454x454: `/assets/img/default/mc-default-${item.locale}-i_454x454.jpg`,
        i_1200x675: `/assets/img/default/mc-default-${item.locale}-i_1200x675.jpg`,
        i_1200x630: `/assets/img/default/mc-default-${item.locale}-i_1200x630.jpg`,
        i_1920x1080: `/assets/img/default/mc-default-${item.locale}-i_1920x1080.jpg`,
        i_1080x1920: `/assets/img/default/mc-default-${item.locale}-i_1080x1920.jpg`,
        i_452x800: `/assets/img/default/mc-default-${item.locale}-i_452x800.jpg`,
        caption: item.full_name
      });
    }

    if (item.gallery) {
      item.gallery = this.galleryAdapter.adapt(item.gallery);
    }

    item.is_adapted = true;
    return item;
  }
}
