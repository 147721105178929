<div class="container my-12 sm:my-15">

    <header *ngIf="!contentModule.name_hidden" class="md:mx-2/24">
      <h1 class="text-28 md:text-36 text-neutral-900 font-everett font-750 leading-122">
        {{ contentModule.name }}
      </h1>
    </header>

    <div class="flex flex-row flex-wrap justify-start md:mx-2/24">
      <div *ngFor="let slotable of contentModule.slotables; let index = index;"
           class="w-full md:w-12/24 mt-9"
           [ngClass]="{ 'md:pr-2' : index % 2 === 0 , 'md:pl-2' : index % 2 === 1 }">
        <app-slot-a60 [slotable]="slotable" [index]="index"></app-slot-a60>
      </div>
    </div>

</div>
