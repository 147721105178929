import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-system-layout',
  templateUrl: './design-system-layout.component.html',
})
export class DesignSystemLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
