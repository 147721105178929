<div class="w-full" [ngClass]="{'bg-neutral-100' : contentModule.is_highlighted}">
  <section class="container py-12 sm:py-15">

    <header *ngIf="!contentModule.name_hidden" class="md:mx-2/24 mb-5 md:mb-8">
      <h1 class="text-28 md:text-36 text-neutral-900 font-everett font-750 leading-122">
        {{ contentModule.name }}
      </h1>
    </header>

    <div *ngFor="let slotable of contentModule.slotables, let last = last">
      <app-slot-a110 [slotable]="slotable" [ngClass]="{ 'mb-13 sm:mb-7' : !last }"></app-slot-a110>
    </div>

    <div class="mt-6 md:ml-2/24">
      <a *ngIf="contentModule.slot_type !== 'link'"
         class="btn__link"
         [routerLink]="[ eventRoute ]">
        <span i18n="@@show_all">Pogledaj sve</span>
        <fa-icon [icon]="['fal', 'angle-right']" class="ml-2 w-4 h-4"></fa-icon>
      </a>
    </div>
  </section>
</div>
