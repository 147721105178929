import { Injectable } from '@angular/core';
import { Resource } from '../../resource/resource.model';
import { Resource as ResourceInterface } from '../../resource/resource';
import { BaseAdapter } from '../../adapter/adapter.model';

export class MediaAuthor extends Resource<Request> implements ResourceInterface<MediaAuthor> {

  public id: number;
  public name: string;

  public constructor(data?: {}) {
    super(data);
    this.init();
  }

  init(): void {
  }
}

@Injectable({
  providedIn: 'root',
})
export class MediaAuthorAdapter extends BaseAdapter<MediaAuthor> {
  constructor() {
    super(MediaAuthor);
  }
}
