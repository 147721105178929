<div class="relative w-full max-w-max mx-auto bg-neutral-400 image-container-height overflow-hidden">
  <img class="z-20 relative image-dimensions transition-opacity duration-200 ease-out delay-100"
       style="filter: blur(20px); transform: scale(1.1)" src="{{ slotable.microthumb }}" alt="microthumb"
       [ngClass]="imageLoaded ? 'opacity-0' : 'opacity-1'"
  >
  <picture class="absolute inset-0 z-30">
    <source srcset="{{ image.i_452x800 }}" media="(max-width: 768px)">
    <source srcset="{{ image.i_1440x806 }}" media="(min-width: 768px)">
    <source srcset="{{ image.i_1920x1080 }}" media="(min-width: 1024px)">
    <img class="absolute inset-0 object-center object-cover w-full h-full"
         srcset="{{ image.i_496x278 }}" alt="{{ slotable.title }}" (load)="onImageLoad($event)">
  </picture>

  <div class="absolute inset-0 z-40 bg-neutral-black bg-opacity-60">
  </div>

  <div class="absolute top-0 left-0 w-full h-full py-24 z-40">
    <div class="container">
      <div class="flex flex-col items-start text-neutral-white w-full sm:w-13/24">
        <h1 class="text-42 lg:text-68 font-everett font-750 leading">{{ slotable.title }}</h1>
        <div class="flex-1 py-8 overflow-hidden">
          <div class="text-16 lg:text-20 font-700 leading-140"
               [innerHTML]="slotable.description">
          </div>
        </div>
        <a class="btn btn--large btn__secondary block mr-auto"
           href="{{ slotable.url }}"
           i18n="@@more_details">Detaljnije</a>
      </div>
    </div>
  </div>
</div>
