import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DesignSystemComponent } from './components/design-system/design-system.component';

const routes = [
  {
    path: '',
    component: DesignSystemComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
})
export class DesignSystemRoutingModule {
}
