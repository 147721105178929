import {
  AfterViewInit,
  Component,
  ElementRef, Inject,
  Input,
  OnDestroy, OnInit, PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { ContentModuleBaseComponent } from '../content-module-base.component';
import { ContentModule } from '../../models/content-module.model';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { isPlatformBrowser } from '@angular/common';

@Component({
  templateUrl: './content-module-a010.component.html',
  styleUrls: ['./content-module-a010.component.scss']
})
export class ContentModuleA010Component implements OnInit, ContentModuleBaseComponent, AfterViewInit, OnDestroy {
  @Input() contentModule: ContentModule;

  constructor(
    @Inject(PLATFORM_ID) private platformId
  ) {
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const swiper = new Swiper('.swiper-module-a10', {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        navigation: {
          nextEl: '.nav-right-a10',
          prevEl: '.nav-left-a10'
        },
        pagination: {
          el: '.swiper-pagination-a10',
          type: 'bullets',
          clickable: true,
          renderBullet:  (index, className) => {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          }
        },
        autoplay: {
          delay: 5000,
        }
      });
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
