import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { MapInfoWindow } from '@angular/google-maps';
import { ContentModule } from '../../models/content-module.model';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-content-module-a170',
  templateUrl: './content-module-a170.component.html'
})
export class ContentModuleA170Component implements OnInit {

  @Input() contentModule: ContentModule;
  apiLoaded: Observable<boolean>;

  @ViewChild(MapInfoWindow, {static: false}) infoWindow: MapInfoWindow;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    httpClient: HttpClient,
    @Inject(PLATFORM_ID) public platformId,
    private renderer: Renderer2) {
    if (isPlatformBrowser((this.platformId))) {
      this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyAJlHJMZXHAur-1eHtTaP5cS3OTwRt-k9s', 'callback')
        .pipe(
          map(() => true),
          tap(() => {
            this.initOptions();
            this.initMarker();
          }),
          catchError(() => of(false)),
        );
    }
  }


  center = {
    lat: 42.437150829376556,
    lng: 19.263851414419296
  };

  options: google.maps.MapOptions = null;

  pinMarker = null;

  marker = null;

  ngOnInit(): void {
  }

  initMarker(): void {
    this.pinMarker = {
      url: '/assets/img/default/map_pin.png',
      size: new google.maps.Size(40, 40),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(15, 30)
    };
    this.marker = {
      position: {
        lat: 42.437150829376556,
        lng: 19.263851414419296
      },
      name: 'Muzički Centar',
      options: {
        icon: this.pinMarker
      }
    };
  }

  initOptions(): void {
    this.options = {
      mapTypeId: 'roadmap',
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
      zoom: 17,
      maxZoom: 19,
      minZoom: 10,
      styles: [
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#E9E9E9'
            },
            {
              lightness: 17
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              color: '#F5F5F5'
            },
            {
              lightness: 20
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#FFFFFF'
            },
            {
              lightness: 17
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#FFFFFF'
            },
            {
              lightness: 29
            },
            {
              weight: 0.2
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#FFFFFF'
            },
            {
              lightness: 18
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [
            {
              color: '#FFFFFF'
            },
            {
              lightness: 16
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#F5F5F5'
            },
            {
              lightness: 21
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#DEDEDE'
            },
            {
              lightness: 21
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              visibility: 'on'
            },
            {
              color: '#FFFFFF'
            },
            {
              lightness: 16
            }
          ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              saturation: 36
            },
            {
              color: '#333333'
            },
            {
              lightness: 40
            }
          ]
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [
            {
              color: '#F2F2F2'
            },
            {
              lightness: 19
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#FEFEFE'
            },
            {
              lightness: 20
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#FEFEFE'
            },
            {
              lightness: 17
            },
            {
              weight: 1.2
            }
          ]
        }
      ]
    };
  }

}
