<div
  [ngClass]="menuTransition ? 'h-grow' : 'h-0'"
  class="fixed flex grow flex-col top-16 z-10 w-full bg-neutral-900 text-neutral-white overflow-y-auto
  transition-height duration-1000 ease-in-out overflow-hidden">
  <div class="container text-neutral-white text-16 leading-150">
    <div class="flex justify-end items-center pt-3 pb-7">
      <button (click)="navigateToRoute(repertoireRoute)"
              class="w-12 h-12 flex justify-center items-center text-neutral-200 hover:text-neutral-white">
        <fa-icon [icon]="['fal', 'calendar']"
                 class="w-6 h-6 mr-7"></fa-icon>
      </button>
      <button (click)="navigateToRoute(profileRoute)"
              class="w-12 h-12 flex justify-center items-center text-neutral-200 hover:text-neutral-white">
        <fa-icon [icon]="['fal', 'circle-user']"
                 class="w-6 h-6 text-18 flex justify-center items-center mr-6"></fa-icon>
      </button>
    </div>
    <form [formGroup]="searchForm" (ngSubmit)="goToSearch()" class="relative w-full h-12 mb-10">
      <input id="ds-search-field--on-dark"
             type="text"
             i18n-placeholder=”@@header-search-placeholder”
             placeholder="Pretraga"
             autocomplete="off"
             formControlName="searchText"
             class="ds-search-field--on-dark">
      <div class="ds-search-utilities">
        <button *ngIf="searchText.value !== ''"
                (click)="searchForm.value.searchText = ''"
                class="ds-search-utility--on-dark pr-2 h-12 w-12">
          <fa-icon [icon]="['fal','xmark']" class="w-6 h-6"></fa-icon>
        </button>
        <span class="w-[1px] bg-neutral-400 h-6"></span>
        <button [disabled]="searchText.value.length < 3"
                type="submit"
                class="ds-search-utility--on-dark text-neutral-white px-4 h-12 w-12">
          <fa-icon [icon]="['fal','magnifying-glass']"
                   class="w-5 h-5 flex justify-center items-center"
                   [ngClass]="{'text-neutral-400':searchText.value.length < 3}"></fa-icon>
        </button>
      </div>
    </form>
    <div class="w-full flex flex-col">
      <ul *ngIf="menuVisible" class="w-full flex flex-col justify-center items-center">
        <li *ngFor="let menuItem of headerMenu, let i = index"
            [ngClass]="{'pt-10': i > 0 , 'border-b-2 border-neutral-800': !showChildren[i]}"
            class="w-full flex flex-col text-20 -700 leading-140">
          <button class="w-full h-full flex justify-between pb-3 items-center"
                  (click)="updateChildren(i)">
            <span class="font-700">{{ menuItem.title }}</span>
            <fa-icon [ngClass]="{ 'rotate-180': showChildren[i]}"
                     [icon]="['fal', 'chevron-down']"
                     class="w-4 h-4 text-neutral-white flex justify-center items-center transition-transform duration-300"></fa-icon>
          </button>
          <ul *ngIf="showChildren[i]"
              class="flex flex-col space-y-4 font-400 text-16 leading-150">
            <li *ngFor="let item of menuItem.items">
              <button (click)="this.closeMenu.emit()">
                <a [routerLink]="item.type !== 'placeholder' ? item.link : null"
                   [queryParams]="item.query_param">{{ item.title }}</a>
              </button>
            </li>
            <div class="w-full h-05 bg-neutral-400"></div>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="w-full grow flex items-end">
    <div class="w-full flex flex-col justify-center items-center space-y-2 mt-5">
      <app-brand-icons></app-brand-icons>
      <app-brand-bars
        mobWidth="20"
        mobHeight="100"
        mobSpacing="space-x-4"
        width="20"
        height="100"
        spacing="space-x-4"
      ></app-brand-bars>
    </div>
  </div>
</div>

