<div class="h-full flex flex-col">
  <div class="relative w-full aspect-[3/2]">
    <img class="z-20 relative transition-opacity duration-200 ease-out delay-100"
         style="filter: blur(20px); transform: scale(1.1)" src="{{ slotable.microthumb }}" alt="microthumb"
         [ngClass]="imageLoaded ? 'opacity-0' : 'opacity-1'"
    >
    <picture class="absolute inset-0 z-30">
      <source srcset="{{ slotable.image.i_814x457 }}" media="(min-width: 375px)">
      <source srcset="{{ slotable.image.i_496x278 }}" media="(max-width: 375px)">
      <img class="absolute inset-0 object-center object-cover w-full h-full"
           srcset="{{ slotable.image.i_496x278 }}" alt="{{ slotable.title }}" (load)="onImageLoad($event)">
    </picture>
  </div>
  <div class="flex-1 mt-6 sm:mt-8 flex flex-col">
  <span class="block text-22 sm:text-28 font-everett font-750 leading-125">
    {{ slotable.title }}
  </span>
    <div class="flex-1 mt-3 md:mt-4 mb-5 md:pr-4 overflow-hidden">
      <span class="text-18 leading-155">
        {{ slotable.description }}
      </span>
    </div>
  </div>
</div>

