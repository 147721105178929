export const routes: any = {
  me: {
    articles: 'clanci',
    articleDetail: 'clanak',
    artist: 'biografija',
    artistDetail: 'biografija',
    contact: 'kontakt',
    profile: 'profil',
    tickets: 'karte',
    changePassword: 'promjena-sifre',
    auth: 'auth',
    login: 'prijava',
    register: 'registracija',
    forgotPassword: 'zaboravljena-sifra',
    resetPassword: 'promjena-sifre',
    activationCode: 'aktivacioni-kod',
    donations: 'donacije',
    event: 'repertoar',
    archive: 'arhiva',
    page: '',
    search: 'pretraga',
    shop: 'biletarnica',
    donationSuccess: 'uspjesno-placanje',
    donationFail: 'neuspjesno-placanje'
  },
  en: {
    articles: 'articles',
    articleDetail: 'article',
    artist: 'biography',
    artistDetail: 'biography',
    contact: 'contact',
    profile: 'profile',
    tickets: 'tickets',
    changePassword: 'change-password',
    auth: 'auth',
    login: 'login',
    register: 'register',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
    activationCode: 'activation-code',
    donations: 'donations',
    event: 'event',
    archive: 'archive',
    page: '',
    search: 'search',
    shop: 'shop',
    donationSuccess: 'donation-success',
    donationFail: 'donation-fail'
  }
};

export enum RouteName {
  HOME = 'homepage',
  ARTICLE_LIST = 'articles',
  ARTICLE_DETAIL = 'articleDetail',
  ARTIST = 'artist',
  AUTH = 'auth',
  LOGIN = 'login',
  REGISTER = 'register',
  RESET_PASSWORD = 'resetPassword',
  FORGOT_PASSWORD = 'forgotPassword',
  ACTIVATION_CODE = 'activationCode',
  PROFILE = 'profile',
  TICKETS = 'tickets',
  CHANGE_PASSWORD = 'changePassword',
  DONATIONS = 'donations',
  EVENT = 'event',
  ARCHIVE = 'archive',
  CONTACT = 'contact',
  CUSTOM_PAGE = 'customPage',
  NOT_FOUND = 'notFound',
  SEARCH = 'search',
  SHOP = 'shop',
  DONATION_SUCCESS = 'donationSuccess',
  DONATION_FAIL = 'donationFail'
}

export let localeMapping: {} = {
  'sr-Latn-ME': 'me',
  'en-US': 'en',
  en: 'en',
};

export let currentLocale: string = $localize`:@@locale_code_sr_or_en|CurrentLocaleShortCode|CurrentLocaleShortCode:me`;
