import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SeoItem } from './seo-item.model';

@Injectable({
  providedIn: 'root'
})
export class SeoGeneralService {

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) { }

  reset(): void {
    this.titleService.setTitle('');
    this.metaService.updateTag({ name: 'description', content: '' });
    this.metaService.removeTag(`name='keywords'`);
  }

  refresh(seoItem: SeoItem): void {
    this.titleService.setTitle(seoItem.getTitle());
    this.metaService.updateTag({ name: 'description', content: seoItem.getDescription() });

    if (seoItem.keywords) {
      this.metaService.updateTag({ name: 'keywords', content: seoItem.keywords });
    }
  }
}
