import { Adapter } from '../../../Library/adapter/adapter';
import { Injectable } from '@angular/core';

export class ArtistRoleTranslation {
  locale?: string;
  title?: string;
  id: string;

  constructor(data?: {}) {
    Object.assign(this, data);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ArtistRoleTranslationAdapter implements Adapter<ArtistRoleTranslation> {

  constructor() {
  }

  adapt(data: any): ArtistRoleTranslation {
    const item = data.data;
    if (item.artist_role_id) {
      item.id = item.artist_role_id;
    }
    return new ArtistRoleTranslation(item);
  }

  adaptArray(items: any): ArtistRoleTranslation[] {
    items = items.data.data ? items.data.data : items.data;
    return items.map((item) => {
      return this.adapt({data: item});
    });
  }
}
