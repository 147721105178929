import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Slot } from '../../models/slot.model';

@Component({
  selector: 'app-slot-a40',
  templateUrl: './slot-a40.component.html'
})
export class SlotA40Component implements OnInit, OnChanges {

  @Input() slotable: Slot;
  @Input() index: number;
  @Input() color: string;
  @Input() slot_type: string;
  imageLoaded: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.setColor();
  }

  setColor(): void {
    const style = getComputedStyle(document.getElementById('page-detail'));
    const page_accent = style.getPropertyValue('--page-accent');
    this.color = page_accent !== '' ? page_accent : '#533B90';
  }

  onImageLoad(evt): void {
    if (evt && evt.target) {
      this.imageLoaded = true;
    }
  }

}
