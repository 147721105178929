import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-slot-a120',
  templateUrl: './slot-a120.component.html'
})
export class SlotA120Component implements OnInit {
  @Input() slotable: any;
  imageLoaded: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onImageLoad(evt): void {
    if (evt && evt.target) {
      this.imageLoaded = true;
    }
  }
}
