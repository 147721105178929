import { Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID } from '@angular/core';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { LocaleService } from '../../../Library/services/locale.service';
import { filter, take, tap } from 'rxjs/operators';
import { DetailService } from '../../../@modules/detail/services/detail.service';
import { SubscribeService } from '../../services/subscribe.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  currentYear: number;
  locale: string;
  apiParams: any;
  footerMenu: MenuItem[];
  legalMenu: MenuItem[];
  form: FormGroup;
  semaphores = {
    showErrorMessage: false,
    showSuccessMessage: false
  };
  donationsRoute: string = `/${routesTrans[currentLocale].donations}`;
  repertoireRoute: string = `/${routesTrans[currentLocale].event}`;
  contactRoute: string = `/${routesTrans[currentLocale].contact}`;


  constructor(
    private localeService: LocaleService,
    private detailService: DetailService,
    private subscribeService: SubscribeService,
    @Inject(LOCALE_ID) public localeCode: string,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.locale = this.localeService.getLocale();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });

    this.currentYear = new Date().getFullYear();

    this.detailService.details$.pipe(
      filter(result => result != null),
      tap(result => {
        this.footerMenu = result.additional.menus.footer.items;
      }),
      take(1)
    ).subscribe();
  }

  subscribe(): void {
    if (this.form.invalid) {
      this.semaphores.showErrorMessage = true;
      return;
    }
    this.subscribeService.subscribeToList(this.email.value)
      .subscribe(
        res => {
          this.semaphores.showSuccessMessage = true;
          if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
              this.semaphores.showSuccessMessage = false;
            }, 4000);
          }
        },
        err => {
          this.semaphores.showErrorMessage = false;
        }
      );
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

}
