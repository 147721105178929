import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Page } from '../../models/page.model';
import { BaseComponent } from '../../../../Library/compontents/base.component';
import { SeoService } from '../../../../Library/services/seo/seo.service';
import { LocaleService } from '../../../../Library/services/locale.service';
import { ContentModuleService } from '../../../content-module/services/content-module.service';
import { ModuleGroup } from '../../../content-module/models/content-module.model';
import { GlobalService } from '../../../../Library/services/global.service';

@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
})
export class PageDetailComponent extends BaseComponent implements OnInit, OnDestroy {
  item: Page;
  cleanDescription: any;
  groupedModules: ModuleGroup[];
  color: string = '';
  firstBreadcrumb: string = '';
  showStickyNews: boolean = false;

  constructor(
    protected route: ActivatedRoute,
    protected seoService: SeoService,
    private sanitizer: DomSanitizer,
    private localeService: LocaleService,
    private contentModuleService: ContentModuleService,
    private globalService: GlobalService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platformId,
  ) {
    super(route, seoService, locale, platformId);
  }

  pageAccentColor: string = '';

  ngOnInit(): void {
    if (this.route.snapshot.data.item) {
      this.item = this.route.snapshot.data.item;

      if (this.item.path === 'naslovna') {
        this.showStickyNews = true;
        this.globalService.showHeaderSearchButton.next(false);
      }

      if (this.item.path === 'naslovna') {
        this.localeService.setCurrentRouteTranslationsForItem();
      } else {
        this.localeService.setCurrentRouteTranslationsForItem(this.item);
      }

      if (this.item) {
        this.pageAccentColor = this.item.color;
      }

      this.setAccentColor(this.pageAccentColor);

      this.cleanDescription = this.sanitizer.bypassSecurityTrustHtml(``);

      const mergedModules = this.contentModuleService.mergeModules(this.item.modules);
      this.groupedModules = this.contentModuleService.createModuleGroups(mergedModules);
    }

    this.firstBreadcrumb = this.getFirstBreadcrumbTitle();
  }

  ngOnDestroy(): void {
    this.globalService.showHeaderSearchButton.next(true);
  }

  get style(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`--page-accent: ${this.color}`);
  }

  setAccentColor(color): void {
    if (color) {
      this.color = color;
    }
  }

  download(data): void {
    const downloadLink = document.createElement('a');

    downloadLink.href = data.filename;
    document.body.appendChild(downloadLink);
    downloadLink.download = data.filename;
    downloadLink.click();
    downloadLink.remove();
  }

  getFirstBreadcrumbTitle(): string {
    switch (this.locale) {
      case 'en':
        return 'Fleka Base Website';
      case 'sr-Latn-ME':
        return 'Fleka Base Website';
    }
  }
}

