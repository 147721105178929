import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-brand-bars',
  templateUrl: './brand-bars.component.html'
})
export class BrandBarsComponent implements OnInit {

  @Input() width: number;
  @Input() height: number;
  @Input() spacing: string;
  @Input() mobWidth: number;
  @Input() mobHeight: number;
  @Input() mobSpacing: string;
  isMobile: boolean = isPlatformBrowser(this.platformId) ? window.innerWidth < 768 : true;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 768;
    }
  }

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  ngOnInit(): void {
  }

}
