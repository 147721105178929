<div
  [ngClass]="menuTransition ? 'menu-height' : 'h-0'"
  class="fixed flex flex-col top-16 z-10 w-full bg-neutral-900 text-neutral-white transition-height duration-1500 ease-in-out overflow-hidden">
  <div  class="container">
    <div class="w-full flex justify-center items-center mt-10 relative h-10">
      <div
        [ngClass]="menuTransition ? 'w-full' : 'w-0'"
        class="absolute z-10 top-5 h-05 bg-neutral-800 transition-width-1s delay-200"></div>
      <div *ngIf="menuTransition" class="relative z-20 w-10/24 px-4 bg-neutral-900">
        <form [formGroup]="searchForm"
              (ngSubmit)="goToSearch()" class="relative w-full">
          <input id="ds-search-field--on-dark"
                 type="text"
                 i18n-placeholder=”@@header-search-placeholder”
                 placeholder="Pretraga"
                 autocomplete="off"
                 formControlName="searchText"
                 class="ds-search-field--on-dark">
          <div class="ds-search-utilities">
            <span *ngIf="searchText.value !== ''"
                  (click)="deleteText()"
                  class="ds-search-utility--on-dark pr-4 hover:cursor-pointer">
              <fa-icon [icon]="['fal','xmark']" class="w-5 h-5"></fa-icon>
            </span>
            <span class="w-px bg-neutral-400 h-6"></span>
            <button [disabled]="searchText.value.length < 3"
                    type="submit"
                    class="ds-search-utility--on-dark text-neutral-white px-4 focus:outline-none focus:ring-0">
              <fa-icon [icon]="['fal','magnifying-glass']"
                       class="w-5 h-5"
                       [ngClass]="{'text-neutral-400':searchText.value.length < 3}"></fa-icon>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="menuVisible" class="mt-16 w-full flex flex-row justify-center">
        <span *ngFor="let menuItem of headerMenu, let i = index"
              [ngClass]="i !== headerMenu.length - 1 ? 'pr-1/24' : 'pr-0' "
              class="w-5/24 text-start font-everett text-neutral-white font-750 text-22">
          {{ menuItem.title }}
          <ul class="flex flex-col text-16 font-400 leading-150 space-y-4 mt-6">
            <li *ngFor="let item of menuItem.items" class="hover:underline">
              <button (click)="this.closeMenu.emit()"
                      class="font-archivo font-400 text-16 w-full text-left hover:underline">
                <a [routerLink]="item.link"
                   [queryParams]="item.query_param">
                  {{ item.title }}
                </a>
              </button>
            </li>
          </ul>
        </span>
    </div>
  </div>

  <div *ngIf="menuVisible" class="grow flex items-end fade-in-animation">
    <div class="w-full h-2 flex">
      <div class="w-[20%] h-full bg-template-500"></div>
      <div class="w-[20%] h-full bg-template-400"></div>
      <div class="w-[20%] h-full bg-template-300"></div>
      <div class="w-[20%] h-full bg-template-100"></div>
      <div class="w-[20%] h-full bg-template-200"></div>
    </div>
  </div>

</div>
