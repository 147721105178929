<div class="w-full">
  <div class="relative w-full module-height max-w-max mx-auto bg-neutral-100 overflow-hidden">
    <div class="module-height swiper swiper-module-a10">
      <div class="swiper-wrapper flex flex-row">
        <div *ngFor="let slotable of contentModule.slotables" class="w-full swiper-slide">
          <app-slot-a10 [slotable]="slotable"
                        [slotType]="contentModule.slot_type">
          </app-slot-a10>
        </div>
      </div>
    </div>

    <div class="absolute left-0 right-0 bottom-12 z-40 flex flex-col-reverse">
      <div class="container">
        <div class="sm:ml-9/24 md:ml-10/24 flex flex-col-reverse sm:flex-row justify-between items-center text-neutral-white">
          <div class="sm:pl-8 flex flex-col items-center text-14 font-700 leading-144 uppercase">
            <span i18n="@@music_centre">Muzički Centar</span>
            <span i18n="@@of_montenegro">Crne Gore</span>
          </div>
          <div class="mb-4 sm:mb-0">
            <div class="flex flex-row swiper-pagination-a10"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
