import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { MenuItem, MenuItemAdapter } from './menu-item.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class Menu extends Resource<Request> {

  public slug: string;
  public title: string;
  public type: string;
  public url: string;
  public level: number;
  public state: string;
  public chain: string;
  public items: MenuItem[];

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class MenuAdapter extends BaseAdapter<Menu> {

  constructor(
    private menuItemAdapter: MenuItemAdapter,
    @Inject(LOCALE_ID) private localeCode: string,
  ) {
    super(Menu);
  }

  adapt(data: any): Menu {
    const item = super.adapt(data);

    if (item.items) {
      item.items = this.menuItemAdapter.adaptArray(item.items);
    }

    return item;
  }
}
