import { Injectable } from '@angular/core';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { DateTime } from 'luxon';

export class User {

  id?: number;
  email?: string;
  full_name?: string;
  gender?: string;
  company?: string;
  sector?: string;
  place?: string;
  address?: string;
  telephone?: string;
  city?: string;
  country?: string;
  activated?: number;
  banned?: number;
  interface_language?: string;
  group?: string;
  accessToken?: string;
  is_adapted?: boolean = false;

  public constructor(data?: {}) {
    Object.assign(this, data);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserAdapter extends BaseAdapter<User> {

  constructor() {
    super(User);
  }

  adapt(data: any): User {
    const item = super.adapt(data);

    if (item.is_adapted) {
      return item;
    }

    item.is_adapted = true;

    return item;
  }

}



