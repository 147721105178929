import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../models/slot.model';
import { Media } from '../../../../Library/media/models/media.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-slot-a70',
  templateUrl: './slot-a70.component.html'
})
export class SlotA70Component implements OnInit {

  @Input() slotable: Slot;
  @Input() index: number;
  @Input() slotType: string;
  @Input() moduleName: string;
  @Input() moduleImage: Media;
  imageLoaded: boolean = false;
  heroImage: Media;
  title: string;
  cleanDescription: SafeHtml;

  constructor( private sanitizer: DomSanitizer ) {
  }

  ngOnInit(): void {
    this.cleanDescription = this.sanitizer.bypassSecurityTrustHtml(this.slotable.description);
    this.setTitle();
    this.setImage();
  }

  setTitle(): void {
    if (this.slotType === 'custom-html') {
      this.title = this.moduleName;
    } else {
      if (this.slotable && this.slotable.title) {
        this.title = this.slotable.title;
      }
    }
  }

  setImage(): void {
    if (this.slotType === 'custom-html') {
      this.heroImage = this.moduleImage;
    } else {
      this.heroImage = this.slotable.image;
    }
  }

  onImageLoad(evt): void {
    if (evt && evt.target) {
      this.imageLoaded = true;
    }
  }

}
