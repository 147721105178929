import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-slot-a110',
  templateUrl: './slot-a110.component.html'
})
export class SlotA110Component implements OnInit {
  @Input() slotable: any;

  constructor() {
  }

  ngOnInit(): void {
  }
}
