<div class="flex flex-col md:flex-row items-center relative">
  <div class="a100-background"></div>
  <div class="w-18/24 md:w-6/24 relative aspect-square md:ml-2/24 md:my-13 md:mr-12">
    <img class="z-20 relative transition-opacity duration-200 ease-out delay-100"
         style="filter: blur(20px); transform: scale(1.1)" src="{{ slotable.microthumb }}" alt="microthumb"
         [ngClass]="imageLoaded ? 'opacity-0' : 'opacity-1'"
    >
    <img class="absolute inset-0 object-center object-cover w-full h-full z-30"
         src="{{ slotable.image.i_454x454 }}"
         appImgFallback="/assets/img/default/mc-default-me-i_454x454.jpg"
         alt="{{ slotable.name }}" (load)="onImageLoad($event)">
  </div>
  <div class="flex flex-col w-18/24 md:w-10/24 mt-4 mb-11 md:mt-0 md:mb-0 z-10">
    <span class="text-28 sm:text-36 font-everett font-750 text-neutral-900 leading-122">
      {{ slotable.name }}
    </span>
    <span class="mt-2 sm:mt-4 text-14 sm:text-16 text-neutral-400 font-700 leading-150 uppercase">
      {{ slotable.role }}
    </span>
    <span class="mt-4 text-18 text-neutral-800 leading-155">
      {{ slotable.description }}
    </span>
    <a [routerLink]="[ artistRoute ]"
       class="block mt-4 mr-auto btn btn--large btn__primary"
       i18n="@@more_details">
      Detaljnije
    </a>
  </div>
</div>
