<div class="flex flex-col-reverse md:flex-row">
  <div class="w-full md:w-10/24 mt-7 md:mt-0 md:pr-1/24 flex flex-col justify-center">
    <span class="block text-22 sm:text-36 md:text-22 lg:text-36 text-neutral-900 font-everett font-750 leading-125">
      {{ slotable.title }}
    </span>
    <div class="my-5 sm:my-6">
      <div class="text-18 text-neutral-800 leading-155"
      [innerHTML]="cleanDescription">
      </div>
    </div>
    <a *ngIf="slotType !== 'custom-html'"
       href="{{ slotable.url }}"
       class="block btn btn--large btn__secondary mr-auto">
      <span i18n="@@more_details">Detaljnije</span>
    </a>
  </div>

  <div class="relative w-full md:w-14/24 aspect-[3/2] overflow-hidden">
    <img class="z-20 relative transition-opacity duration-200 ease-out delay-100"
         style="filter: blur(20px); transform: scale(1.1)" src="{{ slotable.microthumb }}" alt="microthumb"
         [ngClass]="imageLoaded ? 'opacity-0' : 'opacity-1'"
    >
    <picture class="absolute inset-0 z-30">
      <source srcset="{{ slotable.image.i_814x457 }}" media="(min-width: 375px)">
      <source srcset="{{ slotable.image.i_496x278 }}" media="(max-width: 375px)">
      <img class="absolute inset-0 object-center object-cover w-full h-full"
           srcset="{{ slotable.image.i_496x278 }}" alt="{{ title }}" (load)="onImageLoad($event)">
    </picture>
  </div>
</div>


