<div class="container mb-20">
  <h2 class="inline-flex text-18 lg:text-22 text-primary font-bold mb-6">Primary buttons</h2>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <button class="btn btn--large btn__primary">Btn primary</button>
    </div>
    <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnPrimary }}</code>
      </pre>
    </div>
  </div>
  <div class="border-t border-neutral-400 my-10"></div>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <button class="btn btn--large btn__primary" disabled>Btn primary</button>
    </div>
    <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnPrimaryDisabled }}</code>
      </pre>
    </div>
  </div>
  <div class="border-t border-neutral-400 my-10"></div>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <button class="btn btn--large btn__primary">
        <span>Btn primary</span>
        <fa-icon [icon]="['fal', 'angle-right']" class="ml-2"></fa-icon>
      </button>
    </div>
    <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnPrimaryIcon }}</code>
      </pre>
    </div>
  </div>
  <div class="border-t border-neutral-400 my-10"></div>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <button class="btn btn--small btn__primary">Btn primary small</button>
    </div>
    <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnPrimarySmall }}</code>
      </pre>
    </div>
  </div>
</div>

<div class="container mb-20">
  <h2 class="inline-flex text-18 lg:text-22 text-primary font-bold mb-6">Secondary buttons</h2>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <button class="btn btn--large btn__secondary">Btn secondary</button>
    </div>
    <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnSecondary }}</code>
      </pre>
    </div>
  </div>
  <div class="border-t border-neutral-400 my-10"></div>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <button class="btn btn--large btn__secondary" disabled>Btn secondary</button>
    </div>
    <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnSecondaryDisabled }}</code>
      </pre>
    </div>
  </div>
  <div class="border-t border-neutral-400 my-10"></div>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <button class="btn btn--large btn__secondary">
        <span>Btn secondary</span>
        <fa-icon [icon]="['fal', 'angle-right']" class="ml-2"></fa-icon>
      </button>
    </div>
    <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnSecondaryIcon }}</code>
      </pre>
    </div>
  </div>
  <div class="border-t border-neutral-400 my-10"></div>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <button class="btn btn--small btn__secondary">Btn secondary small</button>
    </div>
    <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnSecondarySmall }}</code>
      </pre>
    </div>
  </div>
</div>

<div class="container mb-10">
  <h2 class="inline-flex text-18 lg:text-22 font-bold mb-6">Link buttons</h2>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <button class="btn__link">Btn link</button>
    </div>
    <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnLink }}</code>
      </pre>
    </div>
  </div>
  <div class="border-t border-neutral-400 my-10"></div>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <button class="btn__link" disabled>Btn link</button>
    </div>
    <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnLinkDisabled }}</code>
      </pre>
    </div>
  </div>
  <div class="border-t border-neutral-400 my-10"></div>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <button class="btn__link">
        <span>Btn link</span>
        <fa-icon [icon]="['fal', 'angle-right']" class="ml-2"></fa-icon>
      </button>
    </div>
    <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ btnLinkIcon }}</code>
      </pre>
    </div>
  </div>
</div>


<div class="w-full bg-neutral-black py-10 space-y-10">
  <div class="container">
    <h2 class="inline-flex text-18 lg:text-22 text-neutral-white font-bold text-neutral-white">On dark background</h2>
  </div>
  <div class="container">
    <h2 class="inline-flex text-18 lg:text-22 text-neutral-white font-bold mb-6">Primary buttons</h2>
    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-12/24">
        <button class="btn btn--large btn__primary--on-dark">Btn primary</button>
      </div>
      <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
        <code class="text-12 leading-125">{{ btnPrimaryOnDark }}</code>
      </pre>
      </div>
    </div>
    <div class="border-t border-neutral-400 my-10"></div>

    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-12/24">
        <button class="btn btn--large btn__primary--on-dark" disabled>Btn primary</button>
      </div>
      <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
        <code class="text-12 leading-125">{{ btnPrimaryOnDarkDisabled }}</code>
      </pre>
      </div>
    </div>
    <div class="border-t border-neutral-400 my-10"></div>

    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-12/24">
        <button class="btn btn--large btn__primary--on-dark">
          <span>Btn primary</span>
          <fa-icon [icon]="['fal', 'angle-right']" class="ml-2"></fa-icon>
        </button>
      </div>
      <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
        <code class="text-12 leading-125">{{ btnPrimaryOnDarkIcon }}</code>
      </pre>
      </div>
    </div>
    <div class="border-t border-neutral-400 my-10"></div>

    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-12/24">
        <button class="btn btn--small btn__primary">Btn primary small</button>
      </div>
      <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
        <code class="text-12 leading-125">{{ btnPrimaryOnDarkSmall }}</code>
      </pre>
      </div>
    </div>
  </div>

  <div class="container">
    <h2 class="inline-flex text-18 lg:text-22 text-neutral-white font-bold mb-6">Secondary buttons</h2>
    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-12/24">
        <button class="btn btn--large btn__secondary--on-dark">Btn secondary</button>
      </div>
      <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
        <code class="text-12 leading-125">{{ btnSecondaryOnDark }}</code>
      </pre>
      </div>
    </div>
    <div class="border-t border-neutral-400 my-10"></div>

    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-12/24">
        <button class="btn btn--large btn__secondary--on-dark" disabled>Btn secondary</button>
      </div>
      <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
        <code class="text-12 leading-125">{{ btnSecondaryOnDarkDisabled }}</code>
      </pre>
      </div>
    </div>
    <div class="border-t border-neutral-400 my-10"></div>

    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-12/24">
        <button class="btn btn--large btn__secondary--on-dark">
          <span>Btn secondary</span>
          <fa-icon [icon]="['fal', 'angle-right']" class="ml-2"></fa-icon>
        </button>
      </div>
      <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
        <code class="text-12 leading-125">{{ btnSecondaryOnDarkIcon }}</code>
      </pre>
      </div>
    </div>
    <div class="border-t border-neutral-400 my-10"></div>

    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-12/24">
        <button class="btn btn--small btn__secondary--on-dark">Btn secondary small</button>
      </div>
      <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
        <code class="text-12 leading-125">{{ btnSecondaryOnDarkSmall }}</code>
      </pre>
      </div>
    </div>
  </div>
  <div class="container">
    <h2 class="inline-flex text-18 lg:text-22 text-neutral-white font-bold mb-6">Link buttons</h2>
    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-12/24">
        <button class="btn__link--on-dark">Btn link</button>
      </div>
      <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
        <code class="text-12 leading-125">{{ btnLinkOnDark }}</code>
      </pre>
      </div>
    </div>
    <div class="border-t border-neutral-400 my-10"></div>

    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-12/24">
        <button class="btn__link--on-dark" disabled>Btn link</button>
      </div>
      <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
        <code class="text-12 leading-125">{{ btnLinkOnDarkDisabled }}</code>
      </pre>
      </div>
    </div>
    <div class="border-t border-neutral-400 my-10"></div>

    <div class="flex flex-col lg:flex-row">
      <div class="w-full lg:w-12/24">
        <button class="btn__link--on-dark">
          <span>Btn link</span>
          <fa-icon [icon]="['fal', 'angle-right']" class="ml-2"></fa-icon>
        </button>
      </div>
      <div class="w-full lg:w-12/24">
      <pre class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
        <code class="text-12 leading-125">{{ btnLinkOnDarkIcon }}</code>
      </pre>
      </div>
    </div>
  </div>
</div>







