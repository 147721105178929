import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../models/slot.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-slot-a150',
  templateUrl: './slot-a150.component.html',
  styleUrls: ['./slot-a150.component.scss']
})
export class SlotA150Component implements OnInit {

  @Input() slotable: Slot;
  @Input() index: number;
  imageLoaded: boolean = false;
  cleanDescription: SafeHtml;

  constructor( private sanitizer: DomSanitizer ) {
  }

  ngOnInit(): void {
    this.cleanDescription = this.sanitizer.bypassSecurityTrustHtml(this.slotable.description);
  }

  onImageLoad(evt): void {
    if (evt && evt.target) {
      this.imageLoaded = true;
    }
  }

}
