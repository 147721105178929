<img class="z-20 relative image-dimensions transition-opacity duration-200 ease-out delay-100"
     style="filter: blur(20px); transform: scale(1.1)" src="{{ slotable.microthumb }}" alt="microthumb"
     [ngClass]="imageLoaded ? 'opacity-0' : 'opacity-1'"
>
<picture class="absolute inset-0 z-30">
  <source srcset="{{ image.i_1920x1080 }}" media="(min-width: 768px)">
  <source srcset="{{ image.i_1440x806 }}" media="(min-width: 375px)">
  <source srcset="{{ image.i_452x800 }}" media="(max-width: 375px)">
  <img class="absolute inset-0 object-center object-cover w-full h-full"
       srcset="{{ image.i_496x278 }}" alt="{{ slotable.title }}" (load)="onImageLoad($event)">
</picture>

<div class="absolute inset-0 z-40 bg-neutral-black bg-opacity-60">
  <div class="container h-full flex items-center">
    <div class="flex flex-col text-neutral-white w-full sm:w-13/24">
      <h1 class="text-42 md:text-68 font-everett font-750 leading">{{ slotable.title }}</h1>
      <a class="block mt-4 sm:mt-5 btn__link--on-dark"
         href="{{ slotable.url }}">
        <span i18n="@@find_out_more">Saznaj više</span>
        <fa-icon [icon]="['fal', 'angle-right']" class="w-3 h-3 ml-2"></fa-icon>
      </a>
    </div>
  </div>
</div>
