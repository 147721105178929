import {Component, Input, OnInit} from '@angular/core';
import {ContentModuleBaseComponent} from '../content-module-base.component';
import {ContentModule} from '../../models/content-module.model';

@Component({
  templateUrl: './content-module-a021.component.html'
})
export class ContentModuleA021Component implements OnInit, ContentModuleBaseComponent {
  @Input() contentModule: ContentModule;

  ngOnInit(): void {
  }
}
