import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DateTime } from 'luxon';
import { Resource } from '../../../Library/resource/resource.model';
import { Media, MediaAdapter } from '../../../Library/media/models/media.model';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';
import { ArtistRole, ArtistRoleAdapter } from '../../artist/models/artist-role.model';

export class Slot extends Resource<Request> {
  id: number;
  name: string;
  title: string;
  description?: string;
  slug: string;
  path: string;
  url: string;
  image: Media;
  microthumb: string;
  locale: string;
  publishedAtDate: Date;
  color?: string;
  venueTitle?: string;
  programTitle?: string;
  performanceTitle?: string;
  startDate?: Date;
  artist_roles: ArtistRole[];
  role: string;
  has_biography: boolean;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SlotAdapter {

  constructor(
    private mediaAdapter: MediaAdapter,
    @Inject(LOCALE_ID) private localeCode: string,
    private artistRoleAdapter: ArtistRoleAdapter
  ) {
  }

  adapt(data: any, slot_type: string): Slot {

    const articleRoute = `/${routesTrans[currentLocale].articleDetail}`;
    const eventRoute = `/${routesTrans[currentLocale].event}`;
    const artistRoute = `/${routesTrans[currentLocale].artist}`;

    const item = data.data;

    switch (this.localeCode) {
      case 'en':
        item.locale = 'en';
        break;
      case 'sr-Latn-ME':
        item.locale = 'me';
        break;
    }

    switch (slot_type) {
      case 'article':
        item.url = articleRoute + '/' + item.slug;
        break;
      case 'event':
        item.slug = item.performance?.translation?.slug;
        item.url = eventRoute + `/` + item.slug;
        item.title = item.performance?.translation?.title;
        item.description = item.performance?.translation?.description;
        item.color = item.performance?.program?.color;
        item.venueTitle = item.venue?.translation?.title;
        item.programTitle = item.performance?.program?.translation?.title;
        item.performanceTitle = item.performance?.translation?.title;
        item.startDate = DateTime.fromSQL(item.start_date, {zone: 'UTC'}).toJSDate();
        if (item.performance?.artist_roles) {
          item.artist_roles = this.artistRoleAdapter.adaptArray(item.performance.artist_roles);
        }
        break;
      case 'artist':
        item.name = item.full_name;
        item.role = item.translation?.role;
        break;
    }

    if (slot_type === 'event' && item.performance && item.performance.image) {
      item.image = item.performance.image;
    } else if (slot_type === 'artist' && item.image) {
      item.image = this.mediaAdapter.adapt({data: item.image});
    } else if (slot_type === 'link' && item.image) {
      item.image = this.mediaAdapter.adapt({data: item.image});
    } else if (item.seo_image) {
      item.image = item.seo_image;
    } else {
      item.image = new Media({
        i_378x252: `/assets/img/default/mc-default-${item.locale}-i_378x252.jpg`,
        i_244x136: `/assets/img/default/mc-default-${item.locale}-i_244x136.jpg`,
        i_496x278: `/assets/img/default/mc-default-${item.locale}-i_496x278.jpg`,
        i_454x454: `/assets/img/default/mc-default-${item.locale}-i_454x454.jpg`,
        i_814x457: `/assets/img/default/mc-default-${item.locale}-i_814x457.jpg`,
        i_1200x675: `/assets/img/default/mc-default-${item.locale}-i_1200x675.jpg`,
        i_1200x630: `/assets/img/default/mc-default-${item.locale}-i_1200x630.jpg`,
        i_1920x1080: `/assets/img/default/mc-default-${item.locale}-i_1920x1080.jpg`,
        i_1080x1920: `/assets/img/default/mc-default-${item.locale}-i_1080x1920.jpg`,
        i_452x800: `/assets/img/default/mc-default-${item.locale}-i_452x800.jpg`,
        caption: item.title
      });
    }

    if (item.seo_title) {
      item.title = item.seo_title;
    }

    if (item.name && !item.title) {
      item.title = item.name;
    }

    if (item.image) {
      item.image = this.mediaAdapter.adapt({data: item.image});
    }

    if (item.published_at) {
      item.publishedAtDate = DateTime.fromSQL(item.published_at, {zone: 'UTC'}).toJSDate();
    }

    return new Slot(item);
  }

  adaptArray(items: any, slot_type: string): Slot[] {
    items = (items.data.data) ? items.data.data : items.data;
    return items.map((item) => {
      return this.adapt({data: item}, slot_type);
    });
  }

}
