import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'src/app/@modules/menu/models/menu-item.model';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';
import { Router } from '@angular/router';
import { RouteTranslation } from '../../../Library/services/locale.service';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../../../@modules/auth/services/auth.service';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {

  @Input() headerMenu: MenuItem[];
  @Output() closeMenu = new EventEmitter();
  @Input() menuVisible: boolean;
  @Input() menuTransition: boolean;
  showChildren: boolean[];
  openedChildIndex: number = -1;
  @Input() routeTranslations: RouteTranslation;
  @Input() locale: string;
  searchForm: FormGroup;
  donationsRoute: string = `/${routesTrans[currentLocale].donations}`;
  repertoireRoute: string = `/${routesTrans[currentLocale].event}`;
  contactRoute: string = `/${routesTrans[currentLocale].contact}`;
  profileRoute: string = `${routesTrans[currentLocale].auth}/${routesTrans[currentLocale].login}`;

  constructor(private router: Router,
              private authService: AuthService) {
  }

  goToSearch(): void {
    const params = {
      q: this.searchForm.value.searchText
    };
    this.searchForm.value.searchText = '';
    this.closeMenu.emit();
    this.router.navigate([`/${routesTrans[currentLocale].search}`], {queryParams: params});
  }

  ngOnInit(): void {
    this.authService.user$.subscribe(
      user => {
        if (user !== null) {
          this.profileRoute = `/${routesTrans[currentLocale].profile}`;
        } else {
          this.profileRoute = `${routesTrans[currentLocale].auth}/${routesTrans[currentLocale].login}`;
        }
      }
    );
    this.searchForm = new FormGroup({
      searchText: new FormControl('')
    });
    this.showChildren = Array<boolean>(this.headerMenu?.length);
    for (let i = 0; i < this.showChildren.length; i++) {
      this.showChildren[i] = false;
    }
  }

  updateChildren(index: number): void {
    if (this.openedChildIndex !== -1 && !this.showChildren[index]) {
      this.showChildren[this.openedChildIndex] = false;
      this.openedChildIndex = index;
    } else if (this.openedChildIndex === -1 && !this.showChildren[index]) {
      this.openedChildIndex = index;
    }
    this.showChildren[index] = !this.showChildren[index];
  }

  get searchText(): AbstractControl {
    return this.searchForm.get('searchText');
  }

  navigateToRoute(route: string): void {
    this.closeMenu.emit();
    this.router.navigate([route]);
  }

}
