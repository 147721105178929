<section class="container my-12 sm:my-15">
  <div class="w-full relative">

    <div *ngFor="let slotable of contentModule.slotables; let index = index">
      <app-slot-a40 [slotable]="slotable"
                    [index]="index"
                    [color]="contentModule?.color"
                    [slot_type]="contentModule?.slot_type"></app-slot-a40>
    </div>

  </div>
</section>
