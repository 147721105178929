import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../../resource/resource.service';
import { EnquiriesAdapter, Enquiry } from '../models/enquiries.model';
import { catchError, map } from 'rxjs/operators';
import { ApiError } from '../../api-data/api-error.model';
import { Observable, throwError } from 'rxjs';

@Injectable()

export class EnquiriesService extends ResourceService<Enquiry> {

  constructor(httpClient: HttpClient, adapter: EnquiriesAdapter) {
    super('/enquiries', httpClient, adapter);
  }

  saveAudioForm(data: any, enquityFormId: number): Observable<Enquiry> {
    const fullName: File = new File([data.full_name], 'full_name.ogg');
    const email: File = new File([data.email], 'email.ogg');
    const content: File = new File([data.content], 'content.ogg');
    const formData: FormData = new FormData();
    formData.append('full_name', fullName, 'full_name');
    formData.append('email', email, 'email');
    formData.append('content', content, 'content');

    const params = {
      enquiry_form_id: enquityFormId.toString(),
    };

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.httpClient.post<Enquiry>(this.url + '/audio', formData, {
      headers,
      params,
    }).pipe(
      catchError((response) => {
        const error = new ApiError(response.error);
        return throwError(error);
      }),
      map((response) => {
        return this.adapter.adapt(response);
      }),
    );
  }

}
