<div *ngIf="!isMobile" class="flex flex-row"
     [ngClass]="spacing">
  <div *ngFor="let number of ['500','400','300','200','100']"
       [ngClass]="'bg-template-' + number"
       [ngStyle]="{'width.px': width, 'height.px': height}">
  </div>
</div>
<div *ngIf="isMobile" class="flex flex-row"
     [ngClass]="mobSpacing">
  <div *ngFor="let number of ['500','400','300','200','100']"
       [ngClass]="'bg-template-' + number"
       [ngStyle]="{'width.px': mobWidth, 'height.px': mobHeight}">
  </div>
</div>

