import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../models/slot.model';
import { currentLocale, routes as routesTrans } from '../../../../Library/routes';


@Component({
  selector: 'app-slot-a100',
  templateUrl: './slot-a100.component.html',
  styleUrls: ['./slot-a100.component.scss']
})
export class SlotA100Component implements OnInit {

  @Input() slotable: Slot;
  @Input() index: number;
  imageLoaded: boolean = false;
  artistRoute: string;

  constructor() {
  }

  ngOnInit(): void {
    this.artistRoute = `/${routesTrans[currentLocale].artist}/${this.slotable.slug}`;
  }

  onImageLoad(evt): void {
    if (evt && evt.target) {
      this.imageLoaded = true;
    }
  }

}
