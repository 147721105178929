import {  Injectable } from '@angular/core';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { ProgramTranslation, ProgramTranslationAdapter } from './program-translation.model';

export class Program {
  public id?: number;
  is_adapted: boolean = false;
  color?: string;
  translation?: ProgramTranslation;
  translations?: ProgramTranslation [];

  constructor(
    data?: Partial<Program>,
  ) {
    Object.assign(this, data);
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProgramAdapter extends BaseAdapter<Program> {

  constructor(
    private programTranslationAdapter: ProgramTranslationAdapter) {
    super(Program);
  }

  adapt(data: any): Program {
    const item = super.adapt(data);
    if (item.is_adapted) {
      return item;
    }

    if (item.translation) {
      item.translation = this.programTranslationAdapter.adapt({data: item.translation});
    }

    if (item.translations) {
      item.translations = this.programTranslationAdapter.adaptArray({data: item.translations});
    }

    item.is_adapted = true;
    return item;
  }
}





