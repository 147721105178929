<div class="flex flex-row justify-center items-center text-neutral-200">
  <a href="https://www.facebook.com/people/Muzički-centar-Crne-Gore-Montenegrin-Music-Center/100057327014773/" target="_blank"
     class="text-24 flex justify-center items-center w-12 h-12 hover:text-neutral-white">
    <fa-icon [icon]="['fab', 'facebook']" class="w-6 h-6 flex items-center"></fa-icon>
  </a>
  <a href="https://www.instagram.com/montenegrinmusiccenter/" target="_blank"
     class="text-24 flex justify-center items-center w-12 h-12 hover:text-neutral-white">
    <fa-icon [icon]="['fab', 'instagram']" class="w-6 h-6 flex items-center"></fa-icon>
  </a>
</div>
