import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../../../Library/resource/resource.service';
import { Menu, MenuAdapter } from '../models/menu.model';

@Injectable({
  providedIn: 'root'
})

export class MenuService extends ResourceService<Menu> {
  constructor(httpClient: HttpClient, adapter: MenuAdapter) {
    super('/menus', httpClient, adapter);
  }
}
