import { Component, Input, OnInit } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { Media } from '../../../../Library/media/models/media.model';
import { Slot } from '../../models/slot.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-slot-a21',
  templateUrl: './slot-a21.component.html',
  styleUrls: ['./slot-a21.component.scss']
})
export class SlotA21Component implements OnInit {

  @Input() slotable: Slot;
  @Input() slotType: any;
  @Input() moduleImage: Media;
  faArrowRight = faArrowRight;
  image: Media;
  imageLoaded: boolean = false;
  cleanDescription: SafeHtml;

  constructor( private sanitizer: DomSanitizer ) {
  }

  ngOnInit(): void {
    this.cleanDescription = this.sanitizer.bypassSecurityTrustHtml(this.slotable.description);
    this.setImage();
  }

  setImage(): void {
    if (this.slotType === 'custom-html') {
      this.image = this.moduleImage;
    } else {
      this.image = this.slotable?.image;
    }
  }

  onImageLoad(evt): void {
    if (evt && evt.target) {
      this.imageLoaded = true;
    }
  }
}
