import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class MediaConfigurationService {

  private config = {
    mediaInit: {
      sizes: {
        article: [
          {name: 'i_244x136', width: 244, height: 136},
          {name: 'i_496x278', width: 496, height: 278},
          {name: 'i_454x454', width: 454, height: 454},
          {name: 'i_814x457', width: 814, height: 457},
          {name: 'i_1200x675', width: 1200, height: 675},
          {name: 'i_1200x630', width: 1200, height: 630},
          {name: 'i_1920x1080', width: 1920, height: 1080},
          {name: 'i_1080x1920', width: 1080, height: 1920},
          {name: 'i_1440x806', width: 1440, height: 806},
          {name: 'i_452x800', width: 1440, height: 806},
          {name: 'thumbnail', width: 244, height: 136},
          {name: 'default', width: 1920, height: 1280},
          {name: 'google', width: 1200, height: 630},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ],
        module: [
          {name: 'i_1920x1080', width: 1920, height: 1080},
          {name: 'i_1440x806', width: 1440, height: 806},
          {name: 'i_244x136', width: 244, height: 136},
          {name: 'i_496x278', width: 496, height: 278},
          {name: 'i_454x454', width: 454, height: 454},
          {name: 'i_814x457', width: 814, height: 457},
          {name: 'i_244x136', width: 244, height: 136},
          {name: 'thumbnail', width: 244, height: 136},
          {name: 'default', width: 1920, height: 1280},
          {name: 'google', width: 1200, height: 630},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ],
        event: [
          {name: 'i_1920x1080', width: 1920, height: 1080},
          {name: 'i_1440x806', width: 1440, height: 806},
          {name: 'i_452x800', width: 452, height: 800},
          {name: 'i_454x454', width: 454, height: 454},
          {name: 'i_496x278', width: 496, height: 278},
          {name: 'i_814x457', width: 814, height: 457},
          {name: 'i_1080x1920', width: 1080, height: 1920},
          {name: 'i_1200x675', width: 1200, height: 675},
          {name: 'i_1200x630', width: 1200, height: 630},
          {name: 'thumbnail', width: 244, height: 136},
          {name: 'default', width: 1920, height: 1280},
          {name: 'google', width: 1200, height: 630},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ],
        person: [
          {name: 'i_1920x1080', width: 1920, height: 1080},
          {name: 'i_1200x630', width: 1200, height: 630},
          {name: 'i_1200x675', width: 1200, height: 675},
          {name: 'i_814x457', width: 814, height: 457},
          {name: 'i_452x800', width: 452, height: 800},
          {name: 'i_454x454', width: 454, height: 454},
          {name: 'i_496x278', width: 496, height: 278},
          {name: 'i_244x136', width: 244, height: 136},
          {name: 'thumbnail', width: 244, height: 136},
          {name: 'default', width: 1920, height: 1280},
          {name: 'google', width: 1200, height: 630},
          {name: 'facebook', width: 1200, height: 630},
          {name: 'twitter', width: 1200, height: 675},
        ],
      },
    },
  };

  public getConfig(name: string): any {
    if (!name || (name && !this.config[name])) {
      return null;
    }

    return this.config[name];
  }
}
