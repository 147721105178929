import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/@shared/shared.module';

import { PageDetailComponent } from './components/page-detail/page-detail.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ContentModuleModule } from '../content-module/content-module.module';
import { PagesService } from './services/pages.service';

@NgModule({
  declarations: [
    PageDetailComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FontAwesomeModule,
    ContentModuleModule,
  ],
  providers: [
    PagesService,
  ],
  exports: [
    PageDetailComponent,
  ],
})
export class PagesModule {
}
