import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public showHeaderSearchButton: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public openHeaderSearch: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public is404: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isRedirect: BehaviorSubject<any> = new BehaviorSubject(null);
  public isDenied: BehaviorSubject<boolean> = new BehaviorSubject(false);

  values = {
    montenegrinCities: [
      'Andrijevica',
      'Bar',
      'Berane',
      'Bijelo Polje',
      'Budva', 'Cetinje',
      'Danilovgrad',
      'Golubovci',
      'Gusinje',
      'Herceg Novi',
      'Kolašin',
      'Kotor',
      'Mojkovac',
      'Nikšić',
      'Petnjica',
      'Plav',
      'Pljevlja',
      'Plužine',
      'Podgorica',
      'Rožaje',
      'Šavnik',
      'Tivat',
      'Tuzi',
      'Ulcinj',
      'Žabljak'
    ],
    globalCountries: [
      {code: 'AX', name: 'Alandska ostrva'},
      {code: 'AL', name: 'Albanija'},
      {code: 'DZ', name: 'Alžir'},
      {code: 'VI', name: 'Američka Djevičanska Ostrva'},
      {code: 'AS', name: 'Američka Samoa'},
      {code: 'AD', name: 'Andora'},
      {code: 'AO', name: 'Angola'},
      {code: 'AI', name: 'Angvila'},
      {code: 'AQ', name: 'Antarktik'},
      {code: 'AG', name: 'Antigva i Barbuda'},
      {code: 'AR', name: 'Argentina'},
      {code: 'AW', name: 'Aruba'},
      {code: 'AU', name: 'Australija'},
      {code: 'AT', name: 'Austrija'},
      {code: 'AF', name: 'Avganistan'},
      {code: 'AZ', name: 'Azerbejdžan'},
      {code: 'AP', name: 'Azijsko-Pacifički region'},
      {code: 'BS', name: 'Bahame'}, {code: 'BH', name: 'Bahrein'},
      {code: 'BD', name: 'Bangladeš'},
      {code: 'BB', name: 'Barbados'},
      {code: 'BE', name: 'Belgija'},
      {code: 'BZ', name: 'Belize'},
      {code: 'BJ', name: 'Benin'},
      {code: 'BM', name: 'Bermuda'},
      {code: 'BY', name: 'Bjelorusija'},
      {code: 'BW', name: 'Bocvana'},
      {code: 'BO', name: 'Bolivija'},
      {code: 'BA', name: 'Bosna i Hercegovina'},
      {code: 'CX', name: 'Božićno Ostrvo'},
      {code: 'BR', name: 'Brazil'},
      {code: 'VG', name: 'Britanska Djevičanska Ostrva'},
      {code: 'IO', name: 'Britanska teritorija Indijskog okeana'},
      {code: 'BG', name: 'Bugarska'},
      {code: 'BF', name: 'Burkina Faso'},
      {code: 'BI', name: 'Burundi'},
      {code: 'BT', name: 'Butan'},
      {code: 'BV', name: 'Buve'},
      {code: 'TD', name: 'Čad'},
      {code: 'CF', name: 'Centralnoafrička Republika'},
      {code: 'CZ', name: 'Češka'},
      {code: 'CL', name: 'Čile'},
      {code: 'ME', name: 'Crna Gora'},
      {code: 'DK', name: 'Danska'},
      {code: 'CD', name: 'Demokratska republika Kongo'},
      {code: 'DM', name: 'Dominika'},
      {code: 'DO', name: 'Dominikanska Republika'},
      {code: 'O1', name: 'Druga zemlja'},
      {code: 'JE', name: 'Džerzi'},
      {code: 'DJ', name: 'Džibuti'},
      {code: 'EG', name: 'Egipat'},
      {code: 'EC', name: 'Ekvador'},
      {code: 'GQ', name: 'Ekvatorijalna Gvineja'},
      {code: 'ER', name: 'Eritreja'},
      {code: 'EE', name: 'Estonija'},
      {code: 'ET', name: 'Etiopija'},
      {code: 'EU', name: 'Evropa'},
      {code: 'FO', name: 'Farska Ostrva'},
      {code: 'FM', name: 'Federalne Države Mikronezije'},
      {code: 'FJ', name: 'Fidži'},
      {code: 'PH', name: 'Filipini'},
      {code: 'FI', name: 'Finska'},
      {code: 'FK', name: 'Foklandska Ostrva'},
      {code: 'FR', name: 'Francuska'},
      {code: 'GF', name: 'Francuska Gvajana'},
      {code: 'PF', name: 'Francuska Polinezija'},
      {code: 'TF', name: 'Francuske Južne Teritorije'},
      {code: 'GA', name: 'Gabon'},
      {code: 'GM', name: 'Gambija'},
      {code: 'GH', name: 'Gana'},
      {code: 'GG', name: 'Gernzi'},
      {code: 'GI', name: 'Gibraltar'},
      {code: 'GR', name: 'Grčka'},
      {code: 'GD', name: 'Grenada'},
      {code: 'GL', name: 'Grenland'},
      {code: 'GE', name: 'Gruzija'},
      {code: 'GU', name: 'Guam'},
      {code: 'GP', name: 'Gvadelup'},
      {code: 'GY', name: 'Gvajana'},
      {code: 'GT', name: 'Gvatemala'},
      {code: 'GN', name: 'Gvineja'},
      {code: 'GW', name: 'Gvineja Bisao'},
      {code: 'HT', name: 'Haiti'},
      {code: 'NL', name: 'Holandija'},
      {code: 'AN', name: 'Holandski Antili'},
      {code: 'HN', name: 'Honduras'},
      {code: 'HK', name: 'Hong Kong'},
      {code: 'HR', name: 'Hrvatska'},
      {code: 'IN', name: 'Indija'},
      {code: 'ID', name: 'Indonezija'},
      {code: 'IQ', name: 'Irak'},
      {code: 'IR', name: 'Iran'},
      {code: 'IE', name: 'Irska'},
      {code: 'IS', name: 'Island'},
      {code: 'TL', name: 'Istočni Timor'},
      {code: 'IT', name: 'Italija'},
      {code: 'IL', name: 'Izrael'},
      {code: 'JM', name: 'Jamajka'},
      {code: 'JP', name: 'Japan'},
      {code: 'YE', name: 'Jemen'},
      {code: 'AM', name: 'Jermenija'},
      {code: 'JO', name: 'Jordan'},
      {code: 'GS', name: 'Južna Džordžija i Južna Sendvička Ostrva'},
      {code: 'KR', name: 'Južna Koreja'},
      {code: 'ZA', name: 'Južnoafrička Republika'},
      {code: 'KY', name: 'Kajmanska Ostrva'},
      {code: 'KH', name: 'Kambodža'},
      {code: 'CM', name: 'Kamerun'},
      {code: 'CA', name: 'Kanada'},
      {code: 'QA', name: 'Katar'},
      {code: 'KZ', name: 'Kazahstan'},
      {code: 'KE', name: 'Kenija'},
      {code: 'CN', name: 'Kina'},
      {code: 'CY', name: 'Kipar'},
      {code: 'KG', name: 'Kirgistan'},
      {code: 'KI', name: 'Kiribati'},
      {code: 'CC', name: 'Kokosova Kiling Ostrva'},
      {code: 'CO', name: 'Kolumbija'},
      {code: 'KM', name: 'Komori'},
      {code: 'CG', name: 'Kongo'},
      {code: 'CR', name: 'Kostarika'},
      {code: 'CU', name: 'Kuba'},
      {code: 'CK', name: 'Kukova ostrva'},
      {code: 'KW', name: 'Kuvajt'},
      {code: 'LA', name: 'Laos'},
      {code: 'LV', name: 'Latvija'},
      {code: 'LS', name: 'Lesoto'},
      {code: 'LB', name: 'Liban'},
      {code: 'LR', name: 'Liberija'},
      {code: 'LY', name: 'Libija'},
      {code: 'LI', name: 'Lihtenštajn'},
      {code: 'LT', name: 'Litvanija'},
      {code: 'LU', name: 'Luksemburg'},
      {code: 'MG', name: 'Madagaskar'},
      {code: 'HU', name: 'Mađarska'},
      {code: 'YT', name: 'Majot'},
      {code: 'MO', name: 'Makao'},
      {code: 'MK', name: 'Makedonija'},
      {code: 'UM', name: 'Mala eksterna ostrva SAD'},
      {code: 'MW', name: 'Malavi'},
      {code: 'MV', name: 'Maldivi'},
      {code: 'MY', name: 'Malezija'},
      {code: 'ML', name: 'Mali'},
      {code: 'MT', name: 'Malta'},
      {code: 'MA', name: 'Maroko'},
      {code: 'MH', name: 'Maršalska Ostrva'},
      {code: 'MQ', name: 'Martinik'},
      {code: 'MU', name: 'Mauricijus'},
      {code: 'MR', name: 'Mauritanija'},
      {code: 'MX', name: 'Meksiko'},
      {code: 'MM', name: 'Mjanmar'},
      {code: 'MC', name: 'Monako'},
      {code: 'MN', name: 'Mongolija'},
      {code: 'MS', name: 'Montserat'},
      {code: 'MZ', name: 'Mozambik'},
      {code: 'NA', name: 'Namibija'},
      {code: 'NR', name: 'Nauru'},
      {code: 'NP', name: 'Nepal'},
      {code: 'NE', name: 'Niger'},
      {code: 'NG', name: 'Nigerija'},
      {code: 'NU', name: 'Nijue'},
      {code: 'NI', name: 'Nikaragva'},
      {code: 'DE', name: 'Njemačka'},
      {code: 'NO', name: 'Norveška'},
      {code: 'NC', name: 'Nova Kaledonija'},
      {code: 'NZ', name: 'Novi Zeland'},
      {code: 'CI', name: 'Obala Slonovače'},
      {code: 'OM', name: 'Oman'}, {code: 'HM', name: 'Ostrva Herd i Mekdonald'},
      {code: 'IM', name: 'Ostrvo Man'},
      {code: 'NF', name: 'Ostrvo Norfok'},
      {code: 'PK', name: 'Pakistan'},
      {code: 'PW', name: 'Palau'},
      {code: 'PS', name: 'Palestinska Narodna Samouprava'},
      {code: 'PA', name: 'Panama'}, {code: 'PG', name: 'Papua Nova Gvineja'},
      {code: 'PY', name: 'Paragvaj'},
      {code: 'PE', name: 'Peru'},
      {code: 'PN', name: 'Pitkern'},
      {code: 'PL', name: 'Poljska'},
      {code: 'PR', name: 'Portoriko'},
      {code: 'PT', name: 'Portugal'},
      {code: 'MD', name: 'Republika Moldavija'},
      {code: 'RE', name: 'Reunion'}, {code: 'RW', name: 'Ruanda'},
      {code: 'RO', name: 'Rumunija'},
      {code: 'RU', name: 'Ruska Federacija'},
      {code: 'SV', name: 'Salvador'},
      {code: 'WS', name: 'Samoa'},
      {code: 'SM', name: 'San Marino'},
      {code: 'ST', name: 'Sao Tome i Principe'},
      {code: 'A2', name: 'Satelitski provajder'},
      {code: 'SA', name: 'Saudijska Arabija'},
      {code: 'SC', name: 'Sejšeli'},
      {code: 'SN', name: 'Senegal'},
      {code: 'KN', name: 'Sent Kits i Nevis'},
      {code: 'SL', name: 'Sijera Leone'},
      {code: 'SG', name: 'Singapur'},
      {code: 'SY', name: 'Sirija'},
      {code: 'US', name: 'Sjedinjene Američke Države'},
      {code: 'KP', name: 'Sjeverna Koreja'},
      {code: 'MP', name: 'Sjeverna Marijanska Ostrva'},
      {code: 'SK', name: 'Slovačka'}, {code: 'SI', name: 'Slovenija'},
      {code: 'SB', name: 'Solomonska Ostrva'},
      {code: 'SO', name: 'Somalija'},
      {code: 'ES', name: 'Španija'},
      {code: 'RS', name: 'Srbija'},
      {code: 'LK', name: 'Šri Lanka'},
      {code: 'SD', name: 'Sudan'},
      {code: 'BN', name: 'Sultanat Brunej Darusalam'},
      {code: 'SR', name: 'Surinam'},
      {code: 'CH', name: 'Švajcarska'},
      {code: 'SJ', name: 'Svalbard and Jan Majen'},
      {code: 'SZ', name: 'Svazilend'},
      {code: 'SE', name: 'Švedska'},
      {code: 'SH', name: 'Sveta Jelena'},
      {code: 'LC', name: 'Sveta Lucija'},
      {code: 'BL', name: 'Sveti Bartolomej'},
      {code: 'MF', name: 'Sveti Martin'},
      {code: 'PM', name: 'Sveti Pjer i Mikelon'},
      {code: 'VC', name: 'Sveti Vinsent i Grenadini'},
      {code: 'TJ', name: 'Tadžikistan'},
      {code: 'TH', name: 'Tajland'},
      {code: 'TW', name: 'Tajvan'},
      {code: 'TZ', name: 'Tanzanija'},
      {code: 'TG', name: 'Togo'},
      {code: 'TK', name: 'Tokelau'},
      {code: 'TO', name: 'Tonga'},
      {code: 'TT', name: 'Trinidad i Tobago'},
      {code: 'TN', name: 'Tunis'},
      {code: 'TM', name: 'Turkmenistan'},
      {code: 'TC', name: 'Turks i Kajkos ostrva'},
      {code: 'TR', name: 'Turska'},
      {code: 'TV', name: 'Tuvalu'},
      {code: 'UG', name: 'Uganda'},
      {code: 'AE', name: 'Ujedinjeni Arapski Emirati'},
      {code: 'GB', name: 'Ujedinjeno Kraljevstvo'},
      {code: 'UA', name: 'Ukrajina'},
      {code: 'UY', name: 'Urugvaj'},
      {code: 'UZ', name: 'Uzbekistan'},
      {code: 'WF', name: 'Valis i Futuna'},
      {code: 'VU', name: 'Vanuatu'},
      {code: 'VA', name: 'Vatikan - Sveta Stolica'},
      {code: 'VE', name: 'Venecuela'},
      {code: 'VN', name: 'Vijetnam'},
      {code: 'ZM', name: 'Zambija'},
      {code: 'EH', name: 'Zapadna Sahara'},
      {code: 'CV', name: 'Zelenortska Ostrva'},
      {code: 'ZW', name: 'Zimbabve'}
    ],
    globalCountriesEn: [
      {code: 'AF', name: 'Afghanistan'},
      {code: 'AX', name: 'Åland Islands'},
      {code: 'AL', name: 'Albania'},
      {code: 'DZ', name: 'Algeria'},
      {code: 'AS', name: 'American Samoa'},
      {code: 'AD', name: 'Andorra'},
      {code: 'AO', name: 'Angola'},
      {code: 'AI', name: 'Anguilla'},
      {code: 'AQ', name: 'Antarctica'},
      {code: 'AG', name: 'Antigua and Barbuda'},
      {code: 'AR', name: 'Argentina'},
      {code: 'AM', name: 'Armenia'},
      {code: 'AW', name: 'Aruba'},
      {code: 'AU', name: 'Australia'},
      {code: 'AT', name: 'Austria'},
      {code: 'AZ', name: 'Azerbaijan'},
      {code: 'BS', name: 'Bahamas'},
      {code: 'BH', name: 'Bahrain'},
      {code: 'BD', name: 'Bangladesh'},
      {code: 'BB', name: 'Barbados'},
      {code: 'BY', name: 'Belarus'},
      {code: 'BE', name: 'Belgium'},
      {code: 'BZ', name: 'Belize'},
      {code: 'BJ', name: 'Benin'},
      {code: 'BM', name: 'Bermuda'},
      {code: 'BT', name: 'Bhutan'},
      {code: 'BO', name: 'Bolivia, Plurinational State of'},
      {code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba'},
      {code: 'BA', name: 'Bosnia and Herzegovina'},
      {code: 'BW', name: 'Botswana'},
      {code: 'BV', name: 'Bouvet Island'},
      {code: 'BR', name: 'Brazil'},
      {code: 'IO', name: 'British Indian Ocean Territory'},
      {code: 'BN', name: 'Brunei Darussalam'},
      {code: 'BG', name: 'Bulgaria'},
      {code: 'BF', name: 'Burkina Faso'},
      {code: 'BI', name: 'Burundi'},
      {code: 'KH', name: 'Cambodia'},
      {code: 'CM', name: 'Cameroon'},
      {code: 'CA', name: 'Canada'},
      {code: 'CV', name: 'Cape Verde'},
      {code: 'KY', name: 'Cayman Islands'},
      {code: 'CF', name: 'Central African Republic'},
      {code: 'TD', name: 'Chad'},
      {code: 'CL', name: 'Chile'},
      {code: 'CN', name: 'China'},
      {code: 'CX', name: 'Christmas Island'},
      {code: 'CC', name: 'Cocos (Keeling) Islands'},
      {code: 'CO', name: 'Colombia'},
      {code: 'KM', name: 'Comoros'},
      {code: 'CG', name: 'Congo'},
      {code: 'CD', name: 'Congo, the Democratic Republic of the'},
      {code: 'CK', name: 'Cook Islands'},
      {code: 'CR', name: 'Costa Rica'},
      {code: 'CI', name: 'Côte d\'Ivoire'},
      {code: 'HR', name: 'Croatia'},
      {code: 'CU', name: 'Cuba'},
      {code: 'CW', name: 'Curaçao'},
      {code: 'CY', name: 'Cyprus'},
      {code: 'CZ', name: 'Czech Republic'},
      {code: 'DK', name: 'Denmark'},
      {code: 'DJ', name: 'Djibouti'},
      {code: 'DM', name: 'Dominica'},
      {code: 'DO', name: 'Dominican Republic'},
      {code: 'EC', name: 'Ecuador'},
      {code: 'EG', name: 'Egypt'},
      {code: 'SV', name: 'El Salvador'},
      {code: 'GQ', name: 'Equatorial Guinea'},
      {code: 'ER', name: 'Eritrea'},
      {code: 'EE', name: 'Estonia'},
      {code: 'ET', name: 'Ethiopia'},
      {code: 'FK', name: 'Falkland Islands (Malvinas)'},
      {code: 'FO', name: 'Faroe Islands'},
      {code: 'FJ', name: 'Fiji'},
      {code: 'FI', name: 'Finland'},
      {code: 'FR', name: 'France'},
      {code: 'GF', name: 'French Guiana'},
      {code: 'PF', name: 'French Polynesia'},
      {code: 'TF', name: 'French Southern Territories'},
      {code: 'GA', name: 'Gabon'},
      {code: 'GM', name: 'Gambia'},
      {code: 'GE', name: 'Georgia'},
      {code: 'DE', name: 'Germany'},
      {code: 'GH', name: 'Ghana'},
      {code: 'GI', name: 'Gibraltar'},
      {code: 'GR', name: 'Greece'},
      {code: 'GL', name: 'Greenland'},
      {code: 'GD', name: 'Grenada'},
      {code: 'GP', name: 'Guadeloupe'},
      {code: 'GU', name: 'Guam'},
      {code: 'GT', name: 'Guatemala'},
      {code: 'GG', name: 'Guernsey'},
      {code: 'GN', name: 'Guinea'},
      {code: 'GW', name: 'Guinea-Bissau'},
      {code: 'GY', name: 'Guyana'},
      {code: 'HT', name: 'Haiti'},
      {code: 'HM', name: 'Heard Island and McDonald Islands'},
      {code: 'VA', name: 'Holy See (Vatican City State)'},
      {code: 'HN', name: 'Honduras'},
      {code: 'HK', name: 'Hong Kong'},
      {code: 'HU', name: 'Hungary'},
      {code: 'IS', name: 'Iceland'},
      {code: 'IN', name: 'India'},
      {code: 'ID', name: 'Indonesia'},
      {code: 'IR', name: 'Iran, Islamic Republic of'},
      {code: 'IQ', name: 'Iraq'},
      {code: 'IE', name: 'Ireland'},
      {code: 'IM', name: 'Isle of Man'},
      {code: 'IL', name: 'Israel'},
      {code: 'IT', name: 'Italy'},
      {code: 'JM', name: 'Jamaica'},
      {code: 'JP', name: 'Japan'},
      {code: 'JE', name: 'Jersey'},
      {code: 'JO', name: 'Jordan'},
      {code: 'KZ', name: 'Kazakhstan'},
      {code: 'KE', name: 'Kenya'},
      {code: 'KI', name: 'Kiribati'},
      {code: 'KP', name: 'Korea, Democratic People\'s Republic of'},
      {code: 'KR', name: 'Korea, Republic of'},
      {code: 'KW', name: 'Kuwait'},
      {code: 'KG', name: 'Kyrgyzstan'},
      {code: 'LA', name: 'Lao People\'s Democratic Republic'},
      {code: 'LV', name: 'Latvia'},
      {code: 'LB', name: 'Lebanon'},
      {code: 'LS', name: 'Lesotho'},
      {code: 'LR', name: 'Liberia'},
      {code: 'LY', name: 'Libya'},
      {code: 'LI', name: 'Liechtenstein'},
      {code: 'LT', name: 'Lithuania'},
      {code: 'LU', name: 'Luxembourg'},
      {code: 'MO', name: 'Macao'},
      {code: 'MK', name: 'Macedonia, the Former Yugoslav Republic of'},
      {code: 'MG', name: 'Madagascar'},
      {code: 'MW', name: 'Malawi'},
      {code: 'MY', name: 'Malaysia'},
      {code: 'MV', name: 'Maldives'},
      {code: 'ML', name: 'Mali'},
      {code: 'MT', name: 'Malta'},
      {code: 'MH', name: 'Marshall Islands'},
      {code: 'MQ', name: 'Martinique'},
      {code: 'MR', name: 'Mauritania'},
      {code: 'MU', name: 'Mauritius'},
      {code: 'YT', name: 'Mayotte'},
      {code: 'MX', name: 'Mexico'},
      {code: 'FM', name: 'Micronesia, Federated States of'},
      {code: 'MD', name: 'Moldova, Republic of'},
      {code: 'MC', name: 'Monaco'},
      {code: 'MN', name: 'Mongolia'},
      {code: 'ME', name: 'Montenegro'},
      {code: 'MS', name: 'Montserrat'},
      {code: 'MA', name: 'Morocco'},
      {code: 'MZ', name: 'Mozambique'},
      {code: 'MM', name: 'Myanmar'},
      {code: 'NA', name: 'Namibia'},
      {code: 'NR', name: 'Nauru'},
      {code: 'NP', name: 'Nepal'},
      {code: 'NL', name: 'Netherlands'},
      {code: 'NC', name: 'New Caledonia'},
      {code: 'NZ', name: 'New Zealand'},
      {code: 'NI', name: 'Nicaragua'},
      {code: 'NE', name: 'Niger'},
      {code: 'NG', name: 'Nigeria'},
      {code: 'NU', name: 'Niue'},
      {code: 'NF', name: 'Norfolk Island'},
      {code: 'MP', name: 'Northern Mariana Islands'},
      {code: 'NO', name: 'Norway'},
      {code: 'OM', name: 'Oman'},
      {code: 'PK', name: 'Pakistan'},
      {code: 'PW', name: 'Palau'},
      {code: 'PS', name: 'Palestine, State of'},
      {code: 'PA', name: 'Panama'},
      {code: 'PG', name: 'Papua New Guinea'},
      {code: 'PY', name: 'Paraguay'},
      {code: 'PE', name: 'Peru'},
      {code: 'PH', name: 'Philippines'},
      {code: 'PN', name: 'Pitcairn'},
      {code: 'PL', name: 'Poland'},
      {code: 'PT', name: 'Portugal'},
      {code: 'PR', name: 'Puerto Rico'},
      {code: 'QA', name: 'Qatar'},
      {code: 'RE', name: 'Réunion'},
      {code: 'RO', name: 'Romania'},
      {code: 'RU', name: 'Russian Federation'},
      {code: 'RW', name: 'Rwanda'},
      {code: 'BL', name: 'Saint Barthélemy'},
      {code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha'},
      {code: 'KN', name: 'Saint Kitts and Nevis'},
      {code: 'LC', name: 'Saint Lucia'},
      {code: 'MF', name: 'Saint Martin (French part)'},
      {code: 'PM', name: 'Saint Pierre and Miquelon'},
      {code: 'VC', name: 'Saint Vincent and the Grenadines'},
      {code: 'WS', name: 'Samoa'},
      {code: 'SM', name: 'San Marino'},
      {code: 'ST', name: 'Sao Tome and Principe'},
      {code: 'SA', name: 'Saudi Arabia'},
      {code: 'SN', name: 'Senegal'},
      {code: 'RS', name: 'Serbia'},
      {code: 'SC', name: 'Seychelles'},
      {code: 'SL', name: 'Sierra Leone'},
      {code: 'SG', name: 'Singapore'},
      {code: 'SX', name: 'Sint Maarten (Dutch part)'},
      {code: 'SK', name: 'Slovakia'},
      {code: 'SI', name: 'Slovenia'},
      {code: 'SB', name: 'Solomon Islands'},
      {code: 'SO', name: 'Somalia'},
      {code: 'ZA', name: 'South Africa'},
      {code: 'GS', name: 'South Georgia and the South Sandwich Islands'},
      {code: 'SS', name: 'South Sudan'},
      {code: 'ES', name: 'Spain'},
      {code: 'LK', name: 'Sri Lanka'},
      {code: 'SD', name: 'Sudan'},
      {code: 'SR', name: 'Suriname'},
      {code: 'SJ', name: 'Svalbard and Jan Mayen'},
      {code: 'SZ', name: 'Swaziland'},
      {code: 'SE', name: 'Sweden'},
      {code: 'CH', name: 'Switzerland'},
      {code: 'SY', name: 'Syrian Arab Republic'},
      {code: 'TW', name: 'Taiwan, Province of China'},
      {code: 'TJ', name: 'Tajikistan'},
      {code: 'TZ', name: 'Tanzania, United Republic of'},
      {code: 'TH', name: 'Thailand'},
      {code: 'TL', name: 'Timor-Leste'},
      {code: 'TG', name: 'Togo'},
      {code: 'TK', name: 'Tokelau'},
      {code: 'TO', name: 'Tonga'},
      {code: 'TT', name: 'Trinidad and Tobago'},
      {code: 'TN', name: 'Tunisia'},
      {code: 'TR', name: 'Turkey'},
      {code: 'TM', name: 'Turkmenistan'},
      {code: 'TC', name: 'Turks and Caicos Islands'},
      {code: 'TV', name: 'Tuvalu'},
      {code: 'UG', name: 'Uganda'},
      {code: 'UA', name: 'Ukraine'},
      {code: 'AE', name: 'United Arab Emirates'},
      {code: 'GB', name: 'United Kingdom'},
      {code: 'US', name: 'United States'},
      {code: 'UM', name: 'United States Minor Outlying Islands'},
      {code: 'UY', name: 'Uruguay'},
      {code: 'UZ', name: 'Uzbekistan'},
      {code: 'VU', name: 'Vanuatu'},
      {code: 'VE', name: 'Venezuela, Bolivarian Republic of'},
      {code: 'VN', name: 'Viet Nam'},
      {code: 'VG', name: 'Virgin Islands, British'},
      {code: 'VI', name: 'Virgin Islands, U.S.'},
      {code: 'WF', name: 'Wallis and Futuna'},
      {code: 'EH', name: 'Western Sahara'},
      {code: 'YE', name: 'Yemen'},
      {code: 'ZM', name: 'Zambia'},
      {code: 'ZW', name: 'Zimbabwe'}
    ]
  };

  constructor(
    private localeService: LocaleService
  ) {
  }

  getGlobalCountries(): any {
    if (this.localeService.getLocale() === 'en') {
      return this.values.globalCountriesEn;
    }
    return this.values.globalCountries;
  }

  getGlobalCountryByCode(code): any {
    const countries = this.getGlobalCountries();
    for (const country of countries) {
      if (country.code === code) {
        return country;
      }
    }
  }

}
