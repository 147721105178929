import { Component, Input, OnInit } from '@angular/core';
import { Slot } from '../../models/slot.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-slot-a160',
  templateUrl: './slot-a160.component.html',
  styleUrls: ['./slot-a160.component.scss']
})
export class SlotA160Component implements OnInit {

  @Input() slotable: Slot;
  @Input() index: number;
  imageLoaded: boolean = false;
  cleanDescription: SafeHtml;

  constructor( private sanitizer: DomSanitizer ) {
  }

  ngOnInit(): void {
    this.cleanDescription = this.sanitizer.bypassSecurityTrustHtml(this.slotable.description);
  }
}
