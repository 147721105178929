<div class="w-full md:w-20/24 md:ml-2/24">
  <div class="w-full aspect-video relative">
    <iframe class="w-full h-full"
            title="video-player"
            [src]="safeVimeoUrl"
            width="923"
            height="519"
            allowfullscreen>
    </iframe>
  </div>

  <div class="flex flex-row flex-wrap mt-5 items-center mb-20">
    <div
      class="order-2 flex-1 sm:flex-none sm:w-6/24 md:w-5/24 sm:order-1 flex flex-col text-neutral-900 text-14 leading-144 sm:text-16 sm:leading-150">
      <span class="capitalize">{{ slotable.startDate | date: 'EEEE d MMMM' }}</span>
      <span class="font-700">{{ slotable.start_time }}, {{ slotable.venueTitle }}</span>
    </div>
    <div class="order-1 sm:order-2 w-full sm:flex-1 flex flex-col mb-6 sm:mb-0 sm:ml-4 px-6 border-l-6"
         [ngStyle]="{ 'border-color' : slotable.color }">
      <span class="text-16 sm:font-700 leading-150"
            [ngStyle]="{ 'color' : slotable.color }">
        {{ slotable.programTitle }}
      </span>
      <a [routerLink]="[ slotable.url ]"
         class="text-18 text-neutral-900 font-archivo font-750 leading-155">
        {{ slotable.performanceTitle }}
      </a>
    </div>
    <a class="order-3 block btn btn--large btn__secondary self-end"
       [routerLink]="[ slotable.url ]">
      <span i18n="@@more_details">Detaljnije</span>
    </a>
  </div>
</div>


