import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerResponseService } from 'src/app/Library/server-response.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {

  constructor(
    private srs: ServerResponseService,
    private router: Router,
  ) {
    srs.setNotFound();
  }

  ngOnInit(): void {
  }

}
