import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ds-buttons',
  templateUrl: './ds-buttons.component.html'
})
export class DsButtonsComponent implements OnInit {

  btnPrimary: string;
  btnPrimaryDisabled: string;
  btnPrimarySmall: string;
  btnPrimaryIcon: string;
  btnPrimaryOnDark: string;
  btnPrimaryOnDarkDisabled: string;
  btnPrimaryOnDarkSmall: string;
  btnPrimaryOnDarkIcon: string;
  btnSecondary: string;
  btnSecondaryDisabled: string;
  btnSecondarySmall: string;
  btnSecondaryIcon: string;
  btnSecondaryOnDark: string;
  btnSecondaryOnDarkDisabled: string;
  btnSecondaryOnDarkSmall: string;
  btnSecondaryOnDarkIcon: string;
  btnLink: string;
  btnLinkDisabled: string;
  btnLinkIcon: string;
  btnLinkOnDark: string;
  btnLinkOnDarkDisabled: string;
  btnLinkOnDarkIcon: string;

  constructor() {
  }

  ngOnInit(): void {
    this.btnPrimary = `<button class="btn btn--large btn__primary">Btn primary</button>`;
    this.btnPrimaryDisabled = `<button class="btn btn--large btn__primary" disabled>Btn primary</button>`;
    this.btnPrimarySmall = `<button class="btn btn--small btn__primary" disabled>Btn primary</button>`;
    this.btnPrimaryOnDark = `<button class="btn btn--large btn__primary--on-dark">Btn primary</button>`;
    this.btnPrimaryOnDarkDisabled = `<button class="btn btn--large btn__primary--on-dark" disabled>Btn primary</button>`;
    this.btnPrimaryOnDarkSmall = `<button class="btn btn--small btn__primary--on-dark" disabled>Btn primary</button>`;
    this.btnSecondary = `<button class="btn btn--large btn__secondary">Btn secondary</button>`;
    this.btnSecondaryDisabled = `<button class="btn btn--large btn__secondary" disabled>Btn secondary</button>`;
    this.btnSecondarySmall = `<button class="btn btn--small btn__secondary" disabled>Btn secondary</button>`;
    this.btnSecondaryOnDark = `<button class="btn btn--large btn__secondary--on-dark">Btn secondary</button>`;
    this.btnSecondaryOnDarkDisabled = `<button class="btn btn--large btn__secondary--on-dark" disabled>Btn secondary</button>`;
    this.btnSecondaryOnDarkSmall = `<button class="btn btn--small btn__secondary--on-dark" disabled>Btn secondary</button>`;
    this.btnLink = `<button class="btn__link" disabled>Btn secondary</button>`;
    this.btnLinkDisabled = `<button class="btn__link" disabled>Btn secondary</button>`;
    this.btnLinkOnDark = `<button class="btn__link--on-dark" disabled>Btn secondary</button>`;
    this.btnLinkOnDarkDisabled = `<button class="btn__link--on-dark" disabled>Btn secondary</button>`;

    this.btnPrimaryIcon = `
<button class="btn btn--large btn__primary">
  <span>Btn primary</span>
  <fa-icon [icon]="['fal', 'angle-right']" class="ml-2"></fa-icon>
</button>`;
    this.btnPrimaryOnDarkIcon = `
<button class="btn btn--large btn__primary--on-dark">
  <span>Btn primary</span>
  <fa-icon [icon]="['fal', 'angle-right']" class="ml-2"></fa-icon>
</button>`;
    this.btnSecondaryIcon = `
<button class="btn btn--large btn__secondary">
  <span>Btn secondary</span>
  <fa-icon [icon]="['fal', 'angle-right']" class="ml-2"></fa-icon>
</button>`;
    this.btnSecondaryOnDarkIcon = `
<button class="btn btn--large btn__secondary--on-dark">
  <span>Btn secondary</span>
  <fa-icon [icon]="['fal', 'angle-right']" class="ml-2"></fa-icon>
</button>`;
    this.btnLinkIcon = `
<button class="btn__link">
  <span>Btn secondary</span>
  <fa-icon [icon]="['fal', 'angle-right']" class="ml-2"></fa-icon>
</button>`;
    this.btnLinkOnDarkIcon = `
<button class="btn__link--on-dark">
  <span>Btn secondary</span>
  <fa-icon [icon]="['fal', 'angle-right']" class="ml-2"></fa-icon>
</button>`;
  }
}
