<div class="w-full" [ngClass]="{'bg-neutral-100' : contentModule.is_highlighted}">
  <div class="container py-12 pm:my-15" [ngClass]="{'bg-neutral-100' : contentModule.is_highlighted}">

    <header *ngIf="!contentModule.name_hidden" class="md:mx-2/24 mb-9 sm:mb-7">
      <h1 class="text-28 md:text-36 text-neutral-900 font-everett font-750 leading-122">
        {{ contentModule.name }}
      </h1>
    </header>

    <div class="flex flex-row flex-wrap justify-start">
      <div *ngFor="let slotable of contentModule.slotables; let index = index; let last = last"
           class="w-full sm:w-6/24"
           [ngClass]="{ 'sm:ml-0/24 md:ml-2/24' : index % 3 === 0,
           'sm:ml-1/24': index % 3 !== 0,
            'mb-10' : !last}">
        <app-slot-a160 [slotable]="slotable" [index]="index"></app-slot-a160>
      </div>
    </div>
  </div>
</div>
