import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class PageBreadcrumb extends Resource<Request> {
  id: number;
  level: number;
  path: string;
  url: string;
  title: string;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class PageBreadcrumbAdapter extends BaseAdapter<PageBreadcrumb> {

  constructor() {
    super(PageBreadcrumb);
  }

  adapt(data: any): PageBreadcrumb {
    const item = super.adapt(data);

    item.url = item.path;

    return item;
  }
}
