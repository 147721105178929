<div class="container my-10">
  <h2 class="inline-flex text-18 lg:text-22 text-primary font-bold mb-6">Input fields</h2>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <div class="ds-input-wrapper">
        <label for="ds-input-field" class="ds-input-label">Title</label>
        <input id="ds-input-field"
               type="text"
               placeholder="Input text"
               class="ds-input-field">
        <span class="ds-input-error-message">Error message</span>
      </div>
    </div>
    <div class="w-full lg:w-12/24 mt-10 lg:mt-0 ml-0 lg:ml-10">
      <pre
        class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ inputField }}</code>
      </pre>
    </div>
  </div>

  <div class="border-t border-neutral-400 my-10"></div>

  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <div class="relative">
        <input id="ds-search-field"
               type="text"
               placeholder="Search"
               class="ds-search-field">
        <div class="ds-search-utilities">
          <button class="ds-search-utility pr-4 border-r border-neutral-400">
            <fa-icon [icon]="['fal','xmark']"></fa-icon>
          </button>
          <button type="submit" class="ds-search-utility px-4">
            <fa-icon [icon]="['fal','magnifying-glass']"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="w-full lg:w-12/24 mt-10 lg:mt-0 ml-0 lg:ml-10">
      <pre
        class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ searchField }}</code>
      </pre>
    </div>
  </div>
</div>

<div class="container mb-10">
  <h2 class="inline-flex text-18 lg:text-22 text-primary font-bold mb-6">Checkbox</h2>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-12/24">
      <input id="ds-input-checkbox"
             type="checkbox"
             class="ds-input-checkbox">
    </div>
    <div class="w-full lg:w-12/24 mt-10 lg:mt-0">
      <pre
        class="flex p-3 bg-neutral-400 bg-opacity-20 border border-neutral-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="text-12 leading-125">{{ checkbox }}</code>
      </pre>
    </div>
  </div>
</div>

<div class="w-full bg-neutral-black py-10 space-y-10">
  <div class="container">
    <h2 class="inline-flex text-18 lg:text-22 text-neutral-white font-bold text-neutral-white">On dark background</h2>
  </div>
  <div class="container">
    <h2 class="inline-flex text-18 lg:text-22 text-neutral-white font-bold mb-6">Input fields</h2>
    <div class="flex flex-col lg:flex-row">

    </div>
    <div class="flex flex-col lg:flex-row mt-8">
      <div class="ds-input-wrapper w-full">
        <label for="email" class="ds-input-label--on--dark">Label</label>
        <input id="email"
               type="text"
               autocomplete="off"
               i18n-placeholder=”@@label”
               placeholder="placeholder"
               class="ds-input-field--on--dark">
      </div>
      <div class="w-full lg:w-12/24 mt-10 lg:mt-0 ml-0 lg:ml-10">
        <pre
          class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
          <code class="text-12 leading-125">{{ inputFieldOnDark }}</code>
        </pre>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row mt-8">
      <div class="relative w-full h-12">
        <input
          type="text"
          i18n-placeholder=”@@label”
          placeholder="Placeholder"
          autocomplete="off"
          class="ds-search-field--on-dark">
        <div class="ds-search-utilities">
          <button class="ds-search-utility--on-dark pr-4">
            <fa-icon [icon]="['fal','xmark']" class="w-5 h-5"></fa-icon>
          </button>
          <span class="w-[1px] bg-neutral-400 h-6"></span>
          <button class="ds-search-utility--on-dark text-neutral-white px-4">
            <fa-icon [icon]="['fal','magnifying-glass']"
                     class="w-5 h-5"></fa-icon>
          </button>
        </div>
      </div>
      <div class="w-full lg:w-12/24 mt-10 lg:mt-0 ml-0 lg:ml-10">
        <pre
          class="flex p-3 bg-neutral-white border border-neutral-white border-opacity-10 inline-flex text-14 rounded w-full overflow-auto text-neutral-900">
          <code class="text-12 leading-125">{{ searchFieldOnDark }}</code>
        </pre>
      </div>

    </div>
    <div class="border-t border-neutral-400 my-10"></div>
  </div>
</div>
