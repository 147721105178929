import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { Router } from '@angular/router';
import { currentLocale, routes as routesTrans } from '../../../Library/routes';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
})
export class HeaderDesktopComponent implements OnInit, OnChanges {

  @Output() closeMenu = new EventEmitter();
  @Input() headerMenu: MenuItem[];
  @Input() menuVisible: boolean;
  @Input() menuTransition: boolean;
  searchForm: FormGroup;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      searchText: new FormControl('')
    });
  }

  goToSearch(): void {
    const params = {
      q: this.searchForm.value.searchText
    };
    this.searchForm.value.searchText = '';
    this.closeMenu.emit();
    this.router.navigate([`/${routesTrans[currentLocale].search}`], {queryParams: params});
  }

  deleteText(): void {
    this.searchText.setValue('');
  }

  get searchText(): AbstractControl {
    return this.searchForm.get('searchText');
  }

  ngOnChanges(changes: SimpleChanges): void {
  }


}
