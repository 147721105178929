<section class="container my-12 sm:my-15">

  <header *ngIf="!contentModule.name_hidden" class="md:mx-2/24 mb-5 md:mb-8">
    <h1 class="text-28 md:text-36 text-neutral-900 font-everett font-750 leading-122">
      {{ contentModule.name }}
    </h1>
  </header>

  <div *ngFor="let slotable of contentModule.slotables">
    <app-slot-a30 [slotable]="slotable" [videoId]="contentModule.video_url"></app-slot-a30>
  </div>
</section>
