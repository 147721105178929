import { Adapter } from '../../../Library/adapter/adapter';
import { Injectable } from '@angular/core';
import { Media, MediaAdapter } from '../../../Library/media/models/media.model';

export class ArtistTranslation {
  id: string;
  biography?: string;
  role?: string;
  slug?: string;
  locale?: string;

  // SEO
  seo_image_id?: number;
  seo_image_url?: string;
  seo_image?: Media;
  seo_title?: string;
  seo_description?: string;
  seo_keywords?: string;
  seo_is_auto: boolean;

  seo_fb_image_id?: number;
  seo_fb_image?: Media;
  seo_fb_image_url?: string;
  seo_fb_title?: string;
  seo_fb_description?: string;
  seo_fb_keywords?: string;
  seo_fb_is_auto: boolean;

  seo_tw_image_id?: number;
  seo_tw_image?: Media;
  seo_tw_image_url?: string;
  seo_tw_title?: string;
  seo_tw_description?: string;
  seo_tw_keywords?: string;
  seo_tw_is_auto: boolean;

  // SEO END

  constructor(data?: {}) {
    Object.assign(this, data);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ArtistTranslationAdapter implements Adapter<ArtistTranslation> {

  constructor(private mediaAdapter: MediaAdapter) {
  }

  adapt(data: any): ArtistTranslation {
    const item = data.data;

    if (item.seo_image) {
      item.seo_image = this.mediaAdapter.adapt(item.seo_image);
    }

    if (item.seo_fb_image) {
      item.seo_fb_image = this.mediaAdapter.adapt(item.seo_fb_image);
    }

    if (item.seo_tw_image) {
      item.seo_tw_image = this.mediaAdapter.adapt(item.seo_tw_image);
    }

    return new ArtistTranslation(item);
  }

  adaptArray(items: any): ArtistTranslation[] {
    items = items.data.data ? items.data.data : items.data;
    return items.map((item) => {
      return this.adapt({data: item});
    });
  }
}
