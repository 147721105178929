import {Component, Input, OnInit} from '@angular/core';
import {ContentModule} from '../../models/content-module.model';
import { currentLocale, routes as routesTrans } from '../../../../Library/routes';

@Component({
  selector: 'app-content-module-a110',
  templateUrl: './content-module-a110.component.html',
})
export class ContentModuleA110Component implements OnInit {

  @Input() contentModule: ContentModule;
  eventRoute: string = `/${routesTrans[currentLocale].event}`;

  constructor() { }

  ngOnInit(): void {
  }

}
