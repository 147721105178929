import { Injectable } from '@angular/core';
import { Resource } from '../../resource/resource.model';
import { Resource as ResourceInterface } from '../../resource/resource';
import { Media, MediaAdapter } from '../../media/models/media.model';
import { DateTime } from 'luxon';
import { BaseAdapter } from '../../adapter/adapter.model';

export class Gallery extends Resource<Request> implements ResourceInterface<Gallery> {

  cover: string;
  created_at: string;
  creator_id: number;
  description: string;
  id: number;
  media: Media[];
  media_count: number;
  title: string;
  author: any;
  createdAtDate?: Date;
  updated_at: string;

  public toTransliterate = [
    'description',
    'title',
  ];

  public constructor(data?: {}) {
    super(data);
    this.init();
  }

  init(): void {
  }
}

@Injectable({
  providedIn: 'root',
})

export class GalleryAdapter extends BaseAdapter<Gallery> {

  constructor(private mediaAdapter: MediaAdapter) {
    super(Gallery);
  }

  adapt(data: any): Gallery {
    const item = super.adapt(data);

    if (item.media) {
      item.media = this.mediaAdapter.adaptArray(item.media);
    }

    if (item.created_at) {
      item.createdAtDate = DateTime.fromSQL(item.created_at, {zone: 'UTC'}).toJSDate();
    }

    return item;
  }
}
